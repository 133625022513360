<template>
  <!-- Authentication Check -->
  <div v-if="!authenticated" class="w-full flex justify-center items-center h-screen">
    <div class="bg-gray-100 p-10 rounded-lg shadow-lg">
            <h1 class="text-3xl mb-4">Welcome to the Meal Planner</h1>
            <p class="mb-4">To get started please log in or sign up</p>
            <div class="flex justify-between">
              <router-link to="/login" class="bg-s-t text-white rounded px-4 py-2 mr-2">Sign In</router-link>
              <router-link to="/signup" class="bg-interactive text-white rounded px-4 py-2 mr-2">Sign Up</router-link>
            </div>
          </div>
  </div>
  <div v-else-if="!isPaidUser">
    <div class="w-full flex justify-center items-center h-screen">
      <div class="bg-gray-100 p-10 rounded-lg shadow-lg">
        <h1 class="text-3xl mb-4">Welcome to the Meal Planner</h1>
        <p class="mb-4">Upgrade to access this feature</p>
        <div class="flex justify-between">
          <router-link to="/select-plan" class="bg-interactive text-white rounded px-4 py-2 mr-2">Upgrade</router-link>
        </div>
      </div>
    </div>

  </div>

  <!-- Main Content -->
  <div v-else>
    <!-- Selection Screen -->
    <div v-if="!pantryClicked" class="relative w-full h-full min-h-screen md:h-screen md:overflow-hidden">
      <div
        :class="[
          'absolute inset-0 flex transition-all duration-500 ease-in-out',
          isMobile ? 'flex-col' : 'flex-row'
        ]"
      >
        <!-- Left Side -->
        <div
          class="flex-1 flex flex-col justify-center items-center relative md:overflow-hidden cursor-pointer transition-all duration-500 ease-in-out background-left"
          :class="activeHalf === 'left' ? 'flex-grow-[1.2]' : 'flex-grow-[0.8]'"
          @mouseenter="activeHalf = 'left'"
          @mouseleave="activeHalf = null"
          @click="handleLeftClick"
        >
         <!-- SVG Illustration -->
        <div class="absolute inset-0 z-0">
          <!-- Insert Left Side SVG here -->
          <svg
    class="w-full h-full object-cover"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 600"
    preserveAspectRatio="xMidYMid slice"
    aria-labelledby="ingredientsTitle"
    role="img"
  >
    <title id="ingredientsTitle">Fresh Ingredients Illustration</title>
    <!-- SVG content for fresh ingredients -->
    <!-- Example shapes -->
    <rect width="800" height="600" fill="#FFFAF0" />
    <circle cx="200" cy="300" r="100" fill="#FFB6C1" />
    <circle cx="400" cy="200" r="150" fill="#98FB98" />
    <circle cx="600" cy="350" r="120" fill="#87CEFA" />
    <!-- ... more SVG elements ... -->
  </svg>
        </div>
          <!-- Overlay -->
          <div class="absolute inset-0 bg-white bg-opacity-70"></div>
          <!-- Content -->
          <div class="relative z-10 text-center text-gray-900 p-8">
            <h2
              class="text-4xl font-bold mb-4 transition-transform duration-300"
              :class="{'scale-105': activeHalf === 'left'}"
            >Choose Two Ingredients</h2>
            <p
              class="text-lg mb-6 transition-opacity duration-300"
              :class="{'opacity-100': activeHalf === 'left', 'opacity-0': activeHalf !== 'left'}"
            >Quick and easy recipe suggestions</p>
            <button
              class="bg-interactive text-white px-6 py-3 rounded-full text-lg font-semibold transition-transform duration-300 hover:bg-opacity-90"
              :class="{'scale-105': activeHalf === 'left'}"
            >Get Started</button>
          </div>
        </div>

        <!-- Right Side -->
        <div
          class="flex-1 flex flex-col justify-center items-center relative md:overflow-hidden cursor-pointer transition-all duration-500 ease-in-out background-right"
          :class="[
            activeHalf === 'right' ? 'flex-grow-[1.2]' : 'flex-grow-[0.8]',
            !isPaidUser ? 'opacity-70' : '',
          ]"
          @mouseenter="activeHalf = 'right'"
          @mouseleave="activeHalf = null"
          @click="handleRightClick"
        >
         <!-- SVG Illustration -->
      <div class="absolute inset-0 z-0">
        <!-- Insert Right Side SVG here -->
        <svg
    class="w-full h-full object-cover"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 600"
    preserveAspectRatio="xMidYMid slice"
    aria-labelledby="pantryTitle"
    role="img"
  >
    <title id="pantryTitle">Pantry Illustration</title>
    <!-- SVG content for pantry -->
    <!-- Example shapes -->
    <rect width="800" height="600" fill="#FFF5EE" />
    <rect x="100" y="100" width="600" height="400" fill="#DEB887" />
    <rect x="150" y="150" width="500" height="50" fill="#D2B48C" />
    <!-- Shelves and items -->
    <rect x="160" y="220" width="80" height="80" fill="#FF6347" />
    <rect x="260" y="220" width="80" height="80" fill="#FFD700" />
    <rect x="360" y="220" width="80" height="80" fill="#ADFF2F" />
    <rect x="460" y="220" width="80" height="80" fill="#87CEEB" />
    <!-- ... more SVG elements ... -->
  </svg>
      </div>
          <!-- Overlay -->
          <div class="absolute inset-0 bg-white bg-opacity-70"></div>
          <!-- Content -->
          <div class="relative z-10 text-center text-gray-900 p-8">
            <h2
              class="text-4xl font-bold mb-4 transition-transform duration-300"
              :class="{'scale-105': activeHalf === 'right'}"
            >Use Pantry and Get Costs</h2>
            <p
              class="text-lg mb-6 transition-opacity duration-300"
              :class="{'opacity-100': activeHalf === 'right', 'opacity-0': activeHalf !== 'right'}"
            >Select a meal based on what you have</p>
            <button
              class="bg-interactive text-white px-6 py-3 rounded-full text-lg font-semibold transition-transform duration-300 hover:bg-opacity-90"
              :class="{'scale-105': activeHalf === 'right'}"
            >Explore Options</button>
          </div>
          <!-- Upgrade Message -->
          <p
            v-if="!isPaidUser"
            class="absolute bottom-10 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-70 text-white px-5 py-2 rounded-md transition-opacity duration-300"
            :class="{'opacity-100': activeHalf === 'right', 'opacity-0': activeHalf !== 'right'}"
          >Upgrade to access this feature</p>
        </div>
      </div>

      <!-- Click Indicator -->
      <div class="absolute inset-x-0 bottom-10 flex justify-center items-center">
        <p class="text-lg text-gray-100 animate-bounce">Click a side to get started</p>
      </div>
    </div>
    <div v-else class="flex flex-col md:flex-row w-full min-h-screen h-full md:h-screen bg-gray-100 text-gray-800 md:overscroll-contain">
        <div class="w-full md:w-2/3  md:overflow-y-auto flex flex-col justify-center" :class="{'hidden': showHidePantry}">
            <Recipes :useIngredients="true"   class="h-full w-full md:overflow-y-auto md:no-scrollbar md:overscroll-contain" />
        </div>
        <div class="w-full md:block md:w-1/3 md:overscroll-contain" :class="{'hidden': !showHidePantry}">
            <Pantry />
        </div>
      </div>
    </div>
  </template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import Recipes from './Recipes-components/RecipeList.vue';
import Pantry from './Meal-plan-components/Pantry.vue';

const store = useStore();
const authenticated = computed(() => store.state.authenticated);
const isPaidUser = computed(() => store.getters.isPremiumUser);
const isMobile = ref(false);

const activeHalf = ref(null);
const pantryClicked = ref(true);

// Function to check if screen size is mobile
const checkMobile = () => {
  isMobile.value = window.innerWidth < 768;
};

// Handle Left Side Click
const handleLeftClick = () => {
  console.log('Left side clicked');
  // Add your navigation or logic here
};

// Handle Right Side Click
const handleRightClick = () => {
  if (isPaidUser.value) {
    pantryClicked.value = true;
  } else {
    console.log('Upgrade required to access this feature');
    // Optionally, navigate to an upgrade page
  }
};

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', checkMobile);
});
</script>
  
<style scoped>
/* Background Images */

/* Bounce Animation */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(-15%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-bounce {
  animation: bounce 2s infinite;
}

/* Transition Classes */
.transition-all {
  transition: all 0.3s ease-in-out;
}
.absolute.inset-0.z-0 svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>