<template>
  <!--<div class="landing-page font-main bg-page-bg bg-[radial-gradient(#aaaaaa44_1px,transparent_1px)] [background-size:22px_22px] relative">-->
    <div class="landing-page font-main   relative">
    <!-- Fixed Scrolling Banner -->
    <div class="fixed top-14 md:top-20 left-0 right-0 bg-white border-b border-gray-600 overflow-hidden z-30 shadow-sm">
      <div class="scrolling-banner py-2 text-gray-800">
        <span class="inline-block">
          🎉 Limited Time Offer: Get 5 additional days trial or active for every approved recipe you add!
        </span>
        <span class="inline-block">
          🎉 Limited Time Offer: Get 5 additional days trial or active for every approved recipe you add! 🎉
        </span>
      </div>
    </div>
<section class="hero relative pt-12 2xl:pt-0 flex items-center justify-start overflow-hidden  h-screen max-h-[75vh] lg:max-h-[65vh">

  <!-- Background Image -->
  <img src="../assets/home/hero-section.webp" alt="" class="absolute inset-0 w-full h-full object-cover z-0" />

    <!-- Dark Overlay for Readability -->
    <div class="absolute inset-0 bg-black opacity-0 z-10 pointer-events-none"></div>

<!-- Hero Content -->
<div class="container relative z-20 text-center px-6 w-full">

  <div class="w-full xl:w-full relative mx-auto">

    <!-- Headline -->
    <h1 class="text-3xl md:text-5xl 3xl:text-8xl  font-bold font-headers text-interactive mb-6 drop-shadow-lg animate-fade-in">
      Your Diet on Autopilot, <br> Meals and Groceries Made Easy
    </h1>

    <!-- Subheading -->
    <p class="text-md md:text-xl 3xl:text-3xl md:w-2/3 2xl:w-1/2 text-interactive mb-8 mx-auto drop-shadow-lg animate-fade-in">
      Personalised meal plans, shopping lists, and recipes—all in one place, based on your preferences.
    </p>

    <!-- Call to Action Buttons -->
    <div class="flex flex-col md:flex-row justify-center items-center gap-6 mt-4 animate-fade-in-up">
      <button @click="startPlanning" class="w-3/5 md:w-52 h-12 text-md md:text-base bg-interactive hover:bg-interactive-hover text-white rounded-full transition-colors flex items-center justify-center font-button">
        Plan My Weekly Shop
      </button>
      <button @click="scrollToDemo" class="w-3/5 md:w-52 h-12 text-md md:text-base bg-white bg-opacity-80 hover:bg-opacity-100 border border-white text-black rounded-full transition-colors flex items-center justify-center font-button">
        Try Our Demo
      </button>
    </div>

  </div>


</div>
<!-- Statistics Banner -->
<div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 z-20 w-fit inline-flex items-center space-x-4 bg-white bg-opacity-90 backdrop-blur-sm rounded-full shadow-md px-4 py-3 text-sm">
    <div class="flex items-center">
      <svg class="w-4 h-4 mr-1 text-interactive-old" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
      </svg>
      <span class="font-semibold text-gray-900">2K+</span>
      <span class="ml-1 text-gray-800">plans generated</span>
    </div>
    <div class="flex items-center">
      <svg class="w-4 h-4 mr-1 text-interactive-old" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
      </svg>
      <span class="font-semibold text-gray-900">300+</span>
      <span class="ml-1 text-gray-800">recipes</span>
    </div>
  </div>
</section>

<section class="pb-16 pt-4 md:pb-20 md:pt-8 bg-[#F6EAE0]" id="how-it-works">
    <h2 class="text-2xl md:text-3xl 2xl:text-5xl mb-8 text-center font-bold text-interactive">How Meal Matcher Works</h2>
    <div class="flex flex-col md:flex-row w-screen justify-around items-center">
      <div class="text-center mb-8 md:mb-0 md:w-1/4">
        <Vue3Lottie :animationData="settingsLottie" :height="200" :width="200" />
        <h3 class="text-2xl md:text-2xl font-semibold mb-2 text-interactive">Set Your Preferences</h3>
        <p class="text-sm text-gray-700 px-4">Tell us your diet, where you shop, eating schedule, calories, macros and more.<br> We will curate a personal plan with no repeating meals.</p>
      </div>
      <div class="text-center mb-8 md:mb-0 md:w-1/4">
        <Vue3Lottie :animationData="shoppingLottie" :height="200" :width="200" />
        <h3 class="text-2xl md:text-2xl font-semibold mb-2 text-interactive">Follow Our Shopping List</h3>
        <p class="text-sm text-gray-700 px-4">Go to your favorite shop and follow a store specific shopping list, down to the item.</p>
      </div>
      <div class="text-center md:w-1/4">
        <Vue3Lottie :animationData="mealLottie" :height="200" :width="200" />
        <h3 class="text-2xl md:text-2xl font-semibold mb-2 text-interactive">Track Your Plan</h3>
        <p class="text-sm text-gray-700 px-4">Follow our step-by-step meals making anyone a home chef, hitting your goals with style.</p>
      </div>
    </div>
  </section>
  <section class="py-16 bg-white">
        <div class="container mx-auto px-4 w-full md:w-2/3 2xl:w-1/2">
            <h2 class="text-xl md:text-2xl 2xl:text-4xl mb-8 text-center font-bold text-interactive">Health Streamlined</h2>
            
            <div class="grid grid-cols-2 gap-12 text-center">
                <div class="flex flex-col items-center">
                    <svg class="w-12 h-12 mb-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z"></path>
                    </svg>
                    <p class="text-sm text-gray-600">Reduce Leftovers and Spending by Only Buying What You Need</p>
                </div>
                
                <div class="flex flex-col items-center">
                    <svg class="w-12 h-12 mb-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                    </svg>
                    <p class="text-sm text-gray-600">Add Your Own Recipes</p>
                </div>
                
                <div class="flex flex-col items-center">
                    <svg class="w-12 h-12 mb-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path>
                    </svg>
                    <p class="text-sm text-gray-600">No Preservatives, Only UK Supermarket Ingredients</p>
                </div>
                
                <div class="flex flex-col items-center">
                    <svg class="w-12 h-12 mb-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
                    </svg>
                    <p class="text-sm text-gray-600">Macro-Balanced, to Your Specifications</p>
                </div>
                
                <div class="flex flex-col items-center">
                    <svg class="w-12 h-12 mb-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path>
                    </svg>
                    <p class="text-sm text-gray-600">Automatic To Your Specifications</p>
                </div>
                
                <div class="flex flex-col items-center">
                    <svg class="w-12 h-12 mb-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <p class="text-sm text-gray-600">Save Hours Planning Every Week</p>
                </div>
            </div>
        </div>
    </section>
<section class="how-it-works relative py-16 font-main z-20 bg-[#F8F8f8] ">
<div class="container mx-auto">
  <!-- Your Personalized Plan Section -->
  <div class="container mx-auto px-6 relative overflow-visible">
    <div class="text-2xl md:text-3xl 2xl:text-5xl mb-8 text-center font-bold text-interactive">Interactive Dashboard</div>
      <div class="dashboard-container w-full md:w-3/5  mx-auto relative" ref="containerRef">
        <!-- Desktop Phones -->
        <div 
          class="hidden md:block absolute -left-44 2xl:-left-72 top-4 2xl:top-32 3xl:top-52 transform origin-bottom-left transition-transform duration-700 z-30 hover:scale-150 hover:drop-shadow-lg " 
          :style="[ { height: '80%' }]"
          ref="leftPhoneRef"
        >
          <div class="phone-border w-full h-full rounded-3xl border-4 border-[#3b3b3b] overflow-hidden flex items-center justify-center">
            <img src="../assets/home/sl.webp" alt="Shopping List" class="w-full h-full object-cover">
          </div>
          <div class="absolute text-interactive uppercase bg-opacity-40 text-md -top-8 left-0 w-full h-auto">UK Shopping Lists</div>
        </div>

        <div 
          class="hidden md:block absolute -right-44 2xl:-right-72 top-4 2xl:top-32 3xl:top-52 transform origin-bottom-right transition-transform duration-700 z-30 hover:scale-150 hover:drop-shadow-lg" 
          :style="[ { height: '80%' }]"
          ref="rightPhoneRef"
        >
          <div class="phone-border w-full h-full rounded-3xl border-4 border-[#3b3b3b] overflow-hidden flex items-center justify-center">
            <img src="../assets/home/r.webp" alt="Recipe" class="w-full h-full object-cover">
          </div>
          <div class="absolute text-interactive uppercase bg-opacity-40 text-md -top-8 left-0 w-full h-auto">Detailed Recipes</div>
        </div>
    
   

    <!-- Mobile view -->
  <div class="md:hidden relative">
    <div class="flex overflow-x-scroll snap-x snap-mandatory" ref="scrollContainer" @scroll="handleDeliverScroll">
      <!-- Shopping List Phone -->
      <div class="flex-shrink-0 w-full snap-center">
        <div class="phone-border w-48 h-auto mx-auto rounded-3xl border-4 border-[#3b3b3b] overflow-hidden">
          <img src="../assets/home/sl.webp" alt="Shopping List" class="w-full h-full object-cover">
        </div>
        <p class=" text-center mt-2 text-sm text-interactive uppercase">Interactive Shopping Lists</p>
        <div class="feature">
        <svg class="w-12 h-12 mx-auto text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
          <h3 class="text-xl font-semibold mt-2">Smart Shopping Lists</h3>
          <p class="text-gray-600 mt-1">Automatically generated lists based on your meal plans. Telling you what specific items to buy from selected stores</p>
        </div>
      </div>

      <!-- Dashboard -->
      <div class="flex-shrink-0 w-full snap-center">
        <div class="phone-border w-48 h-auto mx-auto rounded-3xl border-4 border-[#3b3b3b] overflow-hidden">
          <img src="../assets/home/phonedashh.webp" alt="Recipe" class="w-full h-full object-cover">
        </div>
        <p class="text-center mt-2 text-sm text-interactive uppercase">Detailed Dashboard</p>
        <div class="feature">
        <svg class="w-12 h-12 mx-auto text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>
        <h3 class="text-xl font-semibold mt-2">Nutrition Tracking</h3>
        <p class="text-gray-600 mt-1">Monitor your macros and nutritional intake effortlessly</p>
      </div>
      </div>

      <!-- Recipe Phone -->
      <div class="flex-shrink-0 w-full snap-center">
        <div class="phone-border w-48 h-auto mx-auto rounded-3xl border-4 border-[#3b3b3b] overflow-hidden">
          <img src="../assets/home/r.webp" alt="Recipe" class="w-full h-full object-cover">
        </div>
        <p class="text-center mt-2 text-sm text-interactive uppercase">Recipe Guides</p>
        <div class="feature">
        <svg class="w-12 h-12 mx-auto text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
        <h3 class="text-xl font-semibold mt-2">Indepth Recipes</h3>
        <p class="text-gray-600 mt-1">Follow along with your meal plan on phone or desktop!</p>
      </div>
      </div>
    </div>

    <!-- Scroll Indicator -->
    <div class="flex justify-center mt-1">
      <div v-for="(_, index) in 3" :key="index" 
           class="w-2 h-2 mx-1 rounded-full transition-all duration-300"
           :class="[activeDeliverSlider === index ? 'bg-orange-500 w-4' : 'bg-gray-300']">
      </div>
    </div>
    
  </div>

  <!-- Desktop view -->
  <div class="hidden md:block dashboard-frame relative z-10">
    <img src="../assets/home/newdash.webp" alt="UK Meal Planning Dashboard" class="w-full rounded-lg shadow-2xl border-4 border-[#3b3b3b] hover:scale-105 hover:drop-shadow-lg  transition-transform duration-700">
  </div>
    
    
  </div>
</div>
<!-- Dashboard features remain unchanged -->
 <div class="w-full flex justify-center">
<div class="hidden md:flex dashboard-features mt-8  grid grid-cols-3 gap-6 text-center">
      <div class="feature">
        <svg class="w-12 h-12 mx-auto text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
        <h3 class="text-xl font-semibold mt-2">Smart Shopping Lists</h3>
        <p class="text-gray-600 mt-1">Automatically generated store specific lists based on your meal plans </p>
      </div>
      <div class="feature">
        <svg class="w-12 h-12 mx-auto text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>
        <h3 class="text-xl font-semibold mt-2">Nutrition Tracking</h3>
        <p class="text-gray-600 mt-1">Monitor your macros and nutritional intake effortlessly</p>
      </div>
      <div class="feature">
        <svg class="w-12 h-12 mx-auto text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
        <h3 class="text-xl font-semibold mt-2">Indepth Recipes</h3>
        <p class="text-gray-600 mt-1">Follow along with your meal plan on phone or desktop!</p>
      </div>
    </div>
</div>
</div>
</section>

<section class="multi-meal-generator pb-20 pt-4 md:py-20 bg-[#F6EAE0]" id="demo-section">
  <div class="container mx-auto px-4">
    <h2 class="text-2xl md:text-3xl 2xl:text-5xl mb-8 text-center font-bold text-interactive">Give Our Demo a Spin!</h2>
    <p class="text-xl text-center text-gray-600 mb-12">Get a taste of our meal planning capabilities. Generate a day of meals based on your preferences!</p>
    
    <!-- Responsive Container -->
    <div 
      :class="[
        'flex max-w-6xl mx-auto transition-all duration-500',
        generatedMeals ? 'flex-col md:flex-row gap-8' : 'flex-col items-center'
      ]"
    >
      <!-- Input Form -->
      <div 
        :class="[
          'bg-white rounded-lg shadow-lg p-8 transition-all duration-500',
          generatedMeals ? 'w-full md:w-1/2' : 'w-full md:w-2/3 lg:w-1/2'
        ]"
      >
        <form @submit.prevent="generateMeals" class="space-y-6">
          <!-- Target Calories -->
          <div>
            <label for="calories" class="block text-lg font-medium text-gray-700">Target Calories</label>
            <p class="text-sm text-gray-500">1600-2400</p>
            <input type="number" id="calories" v-model="calories" min="1600" max="2400" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
          </div>
          
          <!-- Diet Preference -->
          <div>
            <label for="diet" class="block text-lg font-medium text-gray-700">Diet Preference</label>
            <select id="diet" v-model="diet" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
              <option value="Any">Any</option>
              <option value="Vegetarian">Vegetarian</option>
              <option value="Vegan">Vegan</option>
              <option value="Keto">Keto</option>
              <option value="Paleo">Paleo</option>
              <option value="Atkins">Atkins</option>
              <option value="Gluten Free">Gluten Free</option>
              <option value="Dairy Free">Dairy Free</option>
            </select>
          </div>
          
          <!-- Preferred Shop -->
          <div>
            <label for="shop" class="block text-lg font-medium text-gray-700">Preferred Shop</label>
            <select id="shop" v-model="shop" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
              <option value="Tesco">Tesco</option>
              <option value="Sainsburys">Sainsburys</option>
              <option value="M&S">M&S</option>
              <option value="Aldi">Aldi</option>
            </select>
          </div>
          
          <!-- Submit Button -->
          <div>
            <button 
              type="submit" 
              class="w-full py-3 px-6 rounded-lg text-lg font-semibold transition-colors duration-300"
              :class="[
                isButtonDisabled 
                  ? 'bg-gray-400 text-gray-600 cursor-not-allowed' 
                  : 'bg-interactive text-white hover:bg-interactive-hover'
              ]"
              :disabled="isButtonDisabled"
            >
              {{ isButtonDisabled ? `Wait ${cooldownTime}s to go again` : 'Generate a Day' }}
            </button>
          </div>
        </form>
      </div>
      
      <!-- Results Display -->
      <transition name="fade">
        <div v-if="generatedMeals" class="w-full md:w-1/2 bg-white rounded-lg shadow-lg p-8 transition-all duration-500">
          <!-- Total Macros for the Day -->
          <div class="mb-6">
            <h3 class="text-xl font-semibold text-gray-800 mb-4">Total Macros for the Day</h3>
            <div class="grid grid-cols-4 gap-4">
              <div class="text-center">
                <p class="text-sm text-gray-500">Calories</p>
                <p class="font-semibold">{{ totalMacros.calories }}</p>
              </div>
              <div class="text-center">
                <p class="text-sm text-gray-500">Protein</p>
                <p class="font-semibold">{{ totalMacros.protein }}g</p>
              </div>
              <div class="text-center">
                <p class="text-sm text-gray-500">Carbs</p>
                <p class="font-semibold">{{ totalMacros.carbs }}g</p>
              </div>
              <div class="text-center">
                <p class="text-sm text-gray-500">Fat</p>
                <p class="font-semibold">{{ totalMacros.fat }}g</p>
              </div>
            </div>
          </div>
  
          <!-- Individual Meals -->
          <div class="space-y-6 max-h-96 overflow-y-auto">
            <div v-for="meal in Object.values(generatedMeals)" :key="meal.name" class="flex items-start space-x-4">
              <img :src="meal.image" alt="Generated Meal" class="w-24 h-24 object-cover rounded-lg">
              <div>
                <h3 class="text-xl font-semibold text-gray-800">{{ meal.name }}</h3>
                <!-- Meal Macros -->
                <div class="mt-2 grid grid-cols-4 gap-4">
                  <div class="text-center">
                    <p class="text-sm text-gray-500">Calories</p>
                    <p class="font-semibold">{{ meal.calories }}</p>
                  </div>
                  <div class="text-center">
                    <p class="text-sm text-gray-500">Protein</p>
                    <p class="font-semibold">{{ meal.protien }}g</p>
                  </div>
                  <div class="text-center">
                    <p class="text-sm text-gray-500">Carbs</p>
                    <p class="font-semibold">{{ meal.carbs }}g</p>
                  </div>
                  <div class="text-center">
                    <p class="text-sm text-gray-500">Fat</p>
                    <p class="font-semibold">{{ meal.fat }}g</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Shopping List -->
          <div class="mt-6">
            <h4 class="text-lg font-medium text-gray-700 mb-2">Sample Shopping List:</h4>
            <p class="text-sm text-gray-500 mb-2">(3 random items from all meals)</p>
            <ul class="list-disc list-inside space-y-1">
              <li v-for="item in ingredients" :key="item" class="text-gray-600">{{ item }}</li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</section>
<!--
<section class="how-it-works relative py-8 font-main z-20  ">

  <div class="container mx-auto">
    <div class="text-center text-[#3b3b3b]  font-semibold text-2xl xl:text-5xl mb-4 tracking-wider">You Have Complete Control</div>
    
    <div class="container mx-auto px-4">
      
    <div class="relative w-full">
      <div 
      ref="journeyCarousel" 
      class="journey-carousel overflow-x-auto w-full snap-x snap-mandatory scrollbar-hide"
      @scroll="handleJourneyScroll"
    >
    <div class="flex w-full md:w-2/3 3xl:w-1/2 md:grid md:grid-cols-3 gap-4  mx-auto text-start">
         <div class="journey-item w-full  flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
        <div class=" rounded-lg  relative mt-8 ">
              
              <div class="phone-border mb-3 w-fit md:w-fit h-auto mx-auto rounded-3xl  overflow-hidden shadow-xl">
                <img src="../assets/home/pantrys.webp" alt="Favorite Shops" class="w-full h-full object-cover ">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-light text-gray-700 mb-4">Step 1</h3>
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-4">Update Your Pantry</h3>
                <p class="text-gray-700 text-md ">
                        Keep track of what you have on hand. Our system considers your pantry items when planning meals and other tools. The pantry automatically updates after meal plans, ensuring it's always up-to-date!
                      </p>
              </div>
            </div>
          </div>
          <div class="journey-item w-full  flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
            <div class=" rounded-lg   relative mt-8 ">
              
              <div class="phone-border mb-3 w-fit md:w-fit h-auto mx-auto rounded-3xl  overflow-hidden shadow-xl">
                <img src="../assets/home/diets.webp" alt="Dietary Preferences" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-light text-gray-700 mb-4">Step 2</h3>
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-4">Dietary Preferences</h3>
                <p class="text-gray-700 text-md ">Customise your meal plans based on your dietary needs and restrictions.</p>
              </div>
            </div>
          </div>

          <div class="journey-item w-full  flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
            <div class=" rounded-lg relative mt-8 ">
              
              <div class="phone-border mb-3 w-fit md:w-fit h-auto mx-auto rounded-3xl  overflow-hidden shadow-xl">
                <img src="../assets/home/shopss.webp" alt="Favorite Shops" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-light text-gray-700 mb-4">Step 3</h3>
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-4">Your Favorite Shops</h3>
                <p class="text-gray-700 text-md ">Select your preferred stores so you the meal plan can tell you specifically which item to buy.</p>
              </div>
            </div>
          </div>
          

          <div class="journey-item w-full  flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
            <div class=" rounded-lg   relative mt-8 ">
              
              <div class="phone-border mb-3 w-fit md:w-fit h-auto mx-auto rounded-3xl  overflow-hidden shadow-xl">
                <img src="../assets/home/macross.webp" alt="Calorie Goals" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-light text-gray-700 mb-4">Step 4</h3>
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-4">Macro Goals</h3>
                <p class="text-gray-700 text-md ">Set and track your daily calorie, and macro intake for a personalised diet. Great to hit protein goals! </p>
              </div>
            </div>
          </div>
          <div class="journey-item w-full  flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
            <div class=" rounded-lg   relative mt-8 ">
              
              <div class="phone-border mb-3 w-fit md:w-fit h-auto mx-auto rounded-3xl  overflow-hidden shadow-xl">
                <img src="../assets/home/settings.webp" alt="Your Schedule" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-light text-gray-700 mb-4">Step 5</h3>
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-4">Plan Settings</h3>
                <p class="text-gray-700 text-md ">Decide length of plan, for how many people, and how often meals can repeat.</p>
              </div>
            </div>
            </div>
            <div class="journey-item w-full  flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
              <div class=" rounded-lg   relative mt-8 ">
              
              <div class="phone-border mb-3 w-fit md:w-fit h-auto mx-auto rounded-3xl  overflow-hidden shadow-xl">
                <img src="../assets/home/weekly.webp" alt="Calorie Goals" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-light text-gray-700 mb-4">Step 6</h3>
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-4">Your Schedule</h3>
                <p class="text-gray-700 text-md ">Plan your meals around how you like to eat. If you dont have breakfast, fine! Two dinners? Okay, Going out for drinks saturday. Sure!</p>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>-->
    
    <!-- Scroll Indicator -->
     <!--
    <div class="flex justify-center mt-1 md:hidden">
    <div v-for="(_, index) in 6" :key="index" 
         class="w-2 h-2 mx-1 rounded-full transition-all duration-300"
         :class="[activeJourneyItem === index ? 'bg-blue-500 w-4' : 'bg-gray-300']">
    </div>
  </div>-->
  <!-- Additional Information -->
   <!--
  <div class="mt-8 xl:mt-16 text-center">
            <p class="text-lg text-gray-700">
              The main goal is to use the same ingredients in lots of different ways so you've spent less but had variety in meals, saving money and reducing waste.
            </p>
          </div>
  </div>-->

    <!-- Divider -->
     <!--
    <div class="relative my-4 md:my-8 xl:my-16 hidden ">-->
      <!-- Desktop SVGs -->
       <!--
      <div class="hidden md:block">
        <svg width="100%" height="100%" id="svg" preserveAspectRatio="none" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150 h-[50vw] min-h-[390px] max-h-[600px]">
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="50%" x2="100%" y2="50%">
            <stop offset="5%" stop-color="#8ed1fc"></stop>
            <stop offset="95%" stop-color="#f78da7"></stop>
          </linearGradient>
        </defs>
        <path d="M 0,400 L 0,100 C 90.2775119617225,96.67942583732057 180.555023923445,93.35885167464114 273,100 C 365.444976076555,106.64114832535886 460.0574162679425,123.24401913875599 562,128 C 663.9425837320575,132.755980861244 773.2153110047848,125.66507177033492 867,116 C 960.7846889952152,106.33492822966508 1039.0813397129186,94.0956937799043 1132,91 C 1224.9186602870814,87.9043062200957 1332.4593301435407,93.95215311004785 1440,100 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient1)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
        <defs>
          <linearGradient id="gradient2" x1="0%" y1="50%" x2="100%" y2="50%">
            <stop offset="5%" stop-color="#8ed1fc"></stop>
            <stop offset="95%" stop-color="#f78da7"></stop>
          </linearGradient>
        </defs>
        <path d="M 0,400 L 0,233 C 81.70334928229664,238.42583732057415 163.40669856459328,243.85167464114832 256,236 C 348.5933014354067,228.14832535885168 452.07655502392345,207.0191387559809 561,213 C 669.9234449760766,218.9808612440191 784.287081339713,252.07177033492826 883,265 C 981.712918660287,277.92822966507174 1064.7751196172248,270.6937799043062 1155,262 C 1245.2248803827752,253.30622009569376 1342.6124401913876,243.1531100478469 1440,233 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient2)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
      </svg>

      <svg width="100%" height="100%" id="svg" preserveAspectRatio="none" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150 h-[50vw] min-h-[390px] max-h-[600px]" style="transform: rotate(180deg);">
        <defs>
          <linearGradient id="gradient3" x1="100%" y1="50%" x2="0%" y2="50%">
            <stop offset="5%" stop-color="#8ed1fc"></stop>
            <stop offset="95%" stop-color="#f78da7"></stop>
          </linearGradient>
        </defs>
        <path d="M 0,400 L 0,100 C 81.13875598086125,99.8755980861244 162.2775119617225,99.75119617224881 273,110 C 383.7224880382775,120.24880382775119 524.0287081339713,140.87081339712918 612,141 C 699.9712918660287,141.12918660287082 735.6076555023925,120.76555023923446 817,115 C 898.3923444976075,109.23444976076554 1025.5406698564593,118.06698564593302 1137,118 C 1248.4593301435407,117.93301435406698 1344.2296650717703,108.96650717703349 1440,100 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient3)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
        <defs>
          <linearGradient id="gradient4" x1="100%" y1="50%" x2="0%" y2="50%">
            <stop offset="5%" stop-color="#8ed1fc"></stop>
            <stop offset="95%" stop-color="#f78da7"></stop>
          </linearGradient>
        </defs>
        <path d="M 0,400 L 0,233 C 80.61244019138758,207.05741626794259 161.22488038277515,181.11483253588517 246,189 C 330.77511961722485,196.88516746411483 419.7129186602871,238.5980861244019 536,264 C 652.2870813397129,289.4019138755981 795.9234449760766,298.49282296650716 902,281 C 1008.0765550239234,263.50717703349284 1076.5933014354068,219.4306220095694 1160,207 C 1243.4066985645932,194.5693779904306 1341.7033492822966,213.7846889952153 1440,233 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient4)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
      </svg>
      </div>-->
        <!-- Mobile SVGs -->
          <!-- 
        <div class="md:hidden">
          <svg width="100%" height="100%" id="svg" preserveAspectRatio="none" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150 h-[50vw] min-h-[390px] max-h-[600px]">
            <defs>
              <linearGradient id="gradient1" x1="0%" y1="50%" x2="100%" y2="50%">
                <stop offset="5%" stop-color="#8ed1fc"></stop>
                <stop offset="95%" stop-color="#f78da7"></stop>
              </linearGradient>
            </defs>
            <path d="M 0,400 L 0,250 C 90.2775119617225,248.33971291866028 180.555023923445,246.67942583732057 273,250 C 365.444976076555,253.32057416267943 460.0574162679425,261.62200956937804 562,264 C 663.9425837320575,266.377990430622 773.2153110047848,262.832535885167 867,258 C 960.7846889952152,253.167464114833 1039.0813397129186,247.04784688995216 1132,245.5 C 1224.9186602870814,243.95215311004784 1332.4593301435407,246.97607655502392 1440,250 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient1)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
            <defs>
              <linearGradient id="gradient2" x1="0%" y1="50%" x2="100%" y2="50%">
                <stop offset="5%" stop-color="#8ed1fc"></stop>
                <stop offset="95%" stop-color="#f78da7"></stop>
              </linearGradient>
            </defs>
            <path d="M 0,400 L 0,316.5 C 81.70334928229664,319.21291866028707 163.40669856459328,321.92583732057416 256,318 C 348.5933014354067,314.07416267942584 452.07655502392345,303.50956937799043 561,306.5 C 669.9234449760766,309.49043062200957 784.287081339713,326.03588516746413 883,332.5 C 981.712918660287,338.96411483253587 1064.7751196172248,335.34688995215313 1155,331 C 1245.2248803827752,326.65311004784687 1342.6124401913876,321.5765550239234 1440,316.5 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient2)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
          </svg>

          <svg width="100%" height="100%" id="svg" preserveAspectRatio="none" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150 h-[50vw] min-h-[390px] max-h-[600px]" style="transform: rotate(180deg);">
            <defs>
              <linearGradient id="gradient3" x1="100%" y1="50%" x2="0%" y2="50%">
                <stop offset="5%" stop-color="#8ed1fc"></stop>
                <stop offset="95%" stop-color="#f78da7"></stop>
              </linearGradient>
            </defs>
            <path d="M 0,400 L 0,250 C 81.13875598086125,249.93779904306222 162.2775119617225,249.8755980861244 273,255 C 383.7224880382775,260.1244019138756 524.0287081339713,270.4354066985646 612,270.5 C 699.9712918660287,270.5645933014354 735.6076555023925,260.38277511961724 817,257.5 C 898.3923444976075,254.61722488038276 1025.5406698564593,259.03349282296654 1137,259 C 1248.4593301435407,258.96650717703346 1344.2296650717703,254.48325358851675 1440,250 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient3)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
            <defs>
              <linearGradient id="gradient4" x1="100%" y1="50%" x2="0%" y2="50%">
                <stop offset="5%" stop-color="#8ed1fc"></stop>
                <stop offset="95%" stop-color="#f78da7"></stop>
              </linearGradient>
            </defs>
            <path d="M 0,400 L 0,316.5 C 80.61244019138758,303.52870813397127 161.22488038277515,290.5574162679426 246,294.5 C 330.77511961722485,298.4425837320574 419.7129186602871,319.29904306220095 536,332 C 652.2870813397129,344.70095693779905 795.9234449760766,349.24641148325356 902,340.5 C 1008.0765550239234,331.75358851674644 1076.5933014354068,309.7346889952153 1160,303.5 C 1243.4066985645932,297.2653110047847 1341.7033492822966,306.8325358851675 1440,316.5 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient4)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
          </svg>
        </div>

      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div class="   bg-white rounded-full shadow-lg overflow-hidden ">
          <section class="common-challenges py-16" id="common-challenges">
  <h2 class="text-center text-interactive uppercase mb-4">Common Challenges, Our Solution</h2>
  <div class="container mx-auto px-4 flex justify-center">
    
    
    <div class="grid grid-cols-4 xl:w-4/5 gap-8">-->
      <!-- Meal Monotony Card -->
       <!--
      <div class="challenge-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div class="p-6">
          <svg class="w-12 h-12 text-interactive mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
          </svg>
          <h3 class="text-xl font-semibold text-gray-800 mb-3">Meal Monotony</h3>
          <p class="text-gray-600 mb-4">Break free from repetitive meals with our diverse, tailored recipes.</p>
          <div class="flex items-center justify-between text-sm">
            <span class="text-interactive font-medium">75% increased in meal variety within a month</span>
          </div>
        </div>
      </div>
-->
      <!-- Budget Constraints Card -->
       <!-- 
      <div class="challenge-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div class="p-6">
          <svg class="w-12 h-12 text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <h3 class="text-xl font-semibold text-gray-800 mb-3">Budget Constraints</h3>
          <p class="text-gray-600 mb-4">Eat healthy without breaking the bank using our cost-effective meal plans.</p>
          <div class="flex items-center justify-between text-sm">
            <span class="text-green-500 font-medium">£32 avg. weekly savings</span>
          </div>
        </div>
      </div>
-->
      <!-- Food Waste Card -->
        <!--
      <div class="challenge-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div class="p-6">
          <svg class="w-12 h-12 text-blue-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
          </svg>
          <h3 class="text-xl font-semibold text-gray-800 mb-3">Food Waste</h3>
          <p class="text-gray-600 mb-4">Optimise ingredient usage and significantly reduce your food waste.</p>
          <div class="flex items-center justify-between text-sm">
            <span class="text-blue-500 font-medium">30% avg. waste reduction</span>
          </div>
        </div>
      </div>
-->
      <!-- Time Management Card -->
       <!--
      <div class="challenge-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div class="p-6">
          <svg class="w-12 h-12 text-purple-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <h3 class="text-xl font-semibold text-gray-800 mb-3">Time Management</h3>
          <p class="text-gray-600 mb-4">Save time with our automated meal plans and shopping lists.</p>
          <div class="flex items-center justify-between text-sm">
            <span class="text-purple-500 font-medium">3.5 hours saved weekly</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section class="shopping-tools py-20">
    <div class="container mx-auto px-4">
      <h2 class="text-2xl md:text-3xl mb-8 text-center font-semibold text-interactive">
        Our Additional Tools
      </h2>

      <!-- Tab Navigation -->
    <div class="flex justify-center mb-8">
      <div class="bg-[#3c3c3c] p-1 flex rounded-full w-full max-w-2xl">
        <button v-for="(tab, index) in tabs" :key="index"
                @click="activeTab = index"
                class="flex-1 px-2 py-2 rounded-full transition-all duration-300 text-center whitespace-nowrap overflow-hidden"
                :class="[
                  activeTab === index ? 'bg-white text-[#3d3d3d]' : 'bg-[#3c3c3c] text-white',
                  'text-xs sm:text-sm md:text-base lg:text-lg'
                ]">
          <span class="block truncate">{{ tab }}</span>
        </button>
      </div>
    </div>
      <!-- Tab Content -->
      <transition name="fade" mode="out-in">
        <div :key="activeTab">
      <div v-if="activeTab === 0">
        <!-- Shopping List Comparison Content -->
        <p class="text-center text-xl md:text-2xl text-gray-600 mb-8 max-w-3xl mx-auto">
          Effortlessly plan your shopping and find the best deals across your favorite stores.
        </p>

        <div class="relative items-center justify-center gap-8 md:gap-8">
          <!-- Fun Arrow with Loop -->
          <div class="absolute z-20">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 375.01 375.01" class="w-32 h-32 md:w-40 md:h-40 text-interactive transform md:rotate-0 rotate-90">
              <!-- SVG path data -->
            </svg>  
          </div>

          <!-- Output Section -->
          <div class="flex flex-col items-center">
            <ul class="list-disc list-inside text-gray-600 text-sm md:text-base mb-4">
              <li>Comprehensive list of items to purchase</li>
              <li>Best price suggestions and store options</li>
              <li>Have multiple shopping lists saved at once</li>
            </ul>
            <div class="phone-border mb-4 w-full md:w-2/3 h-auto rounded-3xl border-4 border-[#3b3b3b] overflow-hidden">
              <img src="../assets/home/shopping_page.webp" alt="Shopping List Output" class="w-full h-full object-cover">
            </div>
            
          </div>
        </div>
      </div>

      <div v-else-if="activeTab === 1">
        <!-- Add Your Own Recipes Content -->
        <p class="text-center text-xl md:text-2xl text-gray-600 mb-8 max-w-3xl mx-auto">
          Personalise your meal planning by adding your favorite recipes to our system.
        </p>

        <div class="flex flex-col items-center">
          <ul class="list-disc list-inside text-gray-600 text-sm md:text-base mb-4">
            <li>Easy-to-use recipe input interface</li>
            <li>Automatic macro and calorie calculated</li>
            <li>Keep private or make available to everyone</li>
            <li>Will be available in all services we offer for you</li>
          </ul>
          <div class="phone-border mb-4 w-full md:w-2/3 h-auto rounded-3xl border-4 border-[#3b3b3b] overflow-hidden">
            <img src="../assets/home/recipe_create.webp" alt="Add Recipe Interface" class="w-full h-full object-cover">
          </div>
          
        </div>
      </div>

      <div v-else>
        <!-- Use Leftovers Content -->
        <p class="text-center text-xl md:text-2xl text-gray-600 mb-8 max-w-3xl mx-auto">
          Reduce food waste by finding creative recipes for your leftovers.
        </p>

        <div class="flex flex-col items-center">
          <ul class="list-disc list-inside text-gray-600 text-sm md:text-base mb-4">
            <li>Input available ingredients or use your pantry</li>
            <li>Get recipe suggestions based on your leftovers</li>
            <li>Find out the cost if you are missing items</li>
          </ul>
          <div class="phone-border mb-4 w-full md:w-2/3 h-auto rounded-3xl border-4 border-[#3b3b3b] overflow-hidden">
            <img src="../assets/home/use_leftovers.webp" alt="Leftovers Recipe Suggestions" class="w-full h-full object-cover">
          </div>
          
        </div>
      </div>
    </div>
  </transition>

      <!-- Common Additional Engaging Elements -->
      <div class="flex flex-col items-center mt-16">
        <h3 class="text-2xl font-semibold mb-4">Why Use Our Tools?</h3>
        <ul class="grid grid-cols-1 md:grid-cols-3 gap-8 text-gray-600 text-sm md:text-base">
          <li class="flex items-center">
            <svg class="w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            Save time and money on your grocery shopping.
          </li>
          <li class="flex items-center">
            <svg class="w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            Fully intergrated with our meal planning system.
          </li>
          <li class="flex items-center">
            <svg class="w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            Reduce food waste with smart leftover usage.
          </li>
        </ul>
      </div>
    </div>
  </section>

  




  <section class="plans py-16 md:py-24 " id="plans">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-12">
        <h2 class="text-center text-interactive uppercase mb-4">Choose Your Plan</h2>
        <p class="mt-4 text-xl text-gray-600">Select the option that best fits your needs</p>
      </div>
      
      <div class="mt-12 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 lg:gap-8">
        <!-- Shopping List Plan -->
        <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-white">
          <div class="p-6">
            <div class="flex items-center justify-between">
              <h3 class="text-lg font-medium text-gray-900">Shopping List</h3>
              <span class="px-2 py-1 text-xs font-semibold text-green-800 bg-green-100 rounded-full">New</span>
            </div>
            <p class="mt-4 text-sm text-gray-500">For those who want to optimise their grocery shopping</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">Free</span>
            </p>
            <a href="/uk-meal-planner" class="mt-8 block w-full bg-interactive border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-interactive-hover">Sign-up</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h4 class="text-sm font-medium text-gray-900 tracking-wide uppercase">What's included</h4>
            <ul class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Advanced shopping list generation</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Instant price comparisons across UK stores</span>
              </li>
               <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Save 1 shopping list</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Meal Planner Pro -->
        <div class="border border-black rounded-lg shadow-sm divide-y divide-gray-200 bg-white">
          <div class="p-6">
            <div class="flex items-center justify-between">
              <h3 class="text-lg font-medium text-gray-900">Meal Planner Pro</h3>
              <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">Popular</span>
            </div>
            
            <p class="mt-4 text-sm text-gray-500">Comprehensive meal planning for health enthusiasts</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">£7</span>
              <span class="text-base font-medium text-gray-500">/month</span>
            </p>
            <a href="/uk-meal-planner" class="mt-8 block w-full bg-interactive border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-interactive-hover">Get started</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h4 class="text-sm font-medium text-gray-900 tracking-wide uppercase">What's included</h4>
            <ul class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">All features from Shopping List plan</span>
              </li>
              <li class="flex space-x-3">
              <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
              <span class="text-sm text-gray-500">Generate 15 entire meal plans per month</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Plan up to 7 days at once</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Access to Use Leftovers pantry mode to see what you can make</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Add recipes to the database</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Plan for 1 to 4 people</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Save multiple shopping lists</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Personal Trainer Plan -->
        <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-white">
          <div class="p-6">
            <h3 class="text-lg font-medium text-gray-900">Personal Trainer</h3>
            <p class="mt-4 text-sm text-gray-500">Expert meal planning taken care of for all of your clients!</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">£80</span>
              <span class="text-base font-medium text-gray-500">/month</span>
            </p>
            <a href="#" class="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700 curser-disabled">Coming Soon</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h4 class="text-sm font-medium text-gray-900 tracking-wide uppercase">What's included</h4>
            <ul class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">All features from Meal Planner Pro</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Have up to 20 meal plans managed at once</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Automatically email clients there plans and shopping lists</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Save hours of personalised planning</span>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

<section class="faq py-16 font-main relative overflow-hidden w-full md:w-4/5 2xl:w-3/5 mx-auto" id="faq">
    <div class="container mx-auto px-4">
      <div class="flex justify-between gap-6">
        <div class="w-full">
          <div class="content">
            <div class="meta text-lg text-interactive uppercase mb-2">FAQ'S</div>
            <h2 class="text-4xl md:text-6xl mb-4 text-gray-800">Frequently Asked Questions</h2>
            <div class="accordion space-y-4">
              <div v-for="(faq, i) in faqs" :key="i" class="accordion-item border border-gray-300 rounded-lg overflow-hidden">
                <input 
                  type="checkbox" 
                  :id="'accordion-item-' + i" 
                  class="accordion-item-toggle hidden" 
                  v-model="faq.open"
                />
                <label 
                  :for="'accordion-item-' + i" 
                  class="accordion-item-title cursor-pointer block text-xl xl:text-2xl text-gray-800 bg-white p-6"
                >
                  {{ faq.title }}
                </label>
                <div v-show="faq.open" class="accordion-item-content p-6 text-lg bg-white">
                  <p class="text-gray-600">{{ faq.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="contact-us py-16 w-full md:w-4/5 2xl:w-3/5 mx-auto" id="contact">
  <div class="container mx-auto px-4 max-w-6xl">
    <h2 class="text-center text-interactive uppercase mb-4">Contact Us</h2>
    <p class="text-center text-gray-600 mb-8">Have any questions or need further assistance? Reach out to us at:</p>
    <h2 class="text-center text-interactive uppercase mb-4">info@mealmatcher.co.uk</h2>
  </div>
</section>

  </div>
</template>


<script setup>
import axios from 'axios';
import "swiper/swiper-bundle.css";
import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';
import { useRouter } from 'vue-router';
import { Vue3Lottie } from 'vue3-lottie'
import mealLottie from '../assets/home/mealslottie.json';
import shoppingLottie from '../assets/home/shoppingLottie.json';
import settingsLottie from '../assets/home/settingslottie.json';

const router = useRouter();
const authenticated = computed(() => store.state.authenticated);
const hiddenElements = ref([]);
const navigationPrevRef = ref(null);
const navigationNextRef = ref(null);

const generatedMeal = ref(null);
const mealPlanCount = ref(0);
const displayCount = ref(0);
const activeSlider = ref(null);

let cooldownInterval = null

const containerRef = ref(null)
const leftPhoneRef = ref(null)
const rightPhoneRef = ref(null)

const scrollProgress = ref(0)
const activeTab = ref(0);
const tabs = ['Shopping Comparison', 'Create Recipes', 'Use Leftovers'];
const updateScrollProgress = (entries) => {
  const [entry] = entries
  if (entry.isIntersecting) {
    const containerHeight = entry.target.clientHeight
    const containerTop = entry.boundingClientRect.top
    const windowHeight = window.innerHeight
    const visibleHeight = Math.min(containerHeight, windowHeight - containerTop)
    scrollProgress.value = visibleHeight / containerHeight
  }
}

onMounted(() => {
  const observer = new IntersectionObserver(updateScrollProgress, {
    threshold: [...Array(101).keys()].map(x => x / 100),
    rootMargin: "0px 0px -50px 0px"  // Start animation 200px before the bottom of the viewport
  })
  
  if (containerRef.value) {
    observer.observe(containerRef.value)
  }
})

onUnmounted(() => {
  // Clean up the observer if needed
})

const rotateAmount = computed(() => {
  // Start at 15 degrees (slightly tilted) and rotate to 90 degrees
  return 25 + (-20 * scrollProgress.value)
})

const leftPhoneStyle = computed(() => ({
  transform: `translate(-30%, 0%) rotate(-${rotateAmount.value}deg)`
}))

const rightPhoneStyle = computed(() => ({
  transform: `translate(30%, 0%) rotate(${rotateAmount.value}deg)`
}))




const activeDeliverSlider = ref(1);  // Start with dashboard active
const scrollContainer = ref(null);

const scrollToMiddle = () => {
  if (scrollContainer.value) {
    const slideWidth = scrollContainer.value.offsetWidth;
    const middleIndex = 1; // Assuming the middle index is 1
    scrollContainer.value.scrollLeft = middleIndex * slideWidth;
  }
};

const handleDeliverScroll = (event) => {
  const scrollPosition = event.target.scrollLeft;
  const slideWidth = event.target.offsetWidth;
  activeDeliverSlider.value = Math.round(scrollPosition / slideWidth);
};

onMounted(() => {
  scrollToMiddle(); // Ensure this is called after the component is mounted
});



const scrollToDemo = () => {
  const demoSection = document.getElementById('demo-section');
  if (demoSection) {
    demoSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};


const parallaxImage = ref(null)

const handleScroll = () => {
  if (parallaxImage.value) {
    const rect = parallaxImage.value.getBoundingClientRect()
    const windowHeight = window.innerHeight

    if (rect.top < windowHeight && rect.bottom > 0) {
      const scrollPercentage = (windowHeight - rect.top) / (windowHeight + rect.height)
      const moveDistance = 200 // maximum pixels to move
      parallaxImage.value.style.transform = `translateY(${scrollPercentage * moveDistance}px)`
    }
  }
}


onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  if (cooldownInterval) {
    clearInterval(cooldownInterval);
  }
})



const activeJourneyItem = ref(0);  // Start with the first (leftmost) item
const journeyCarousel = ref(null);

const scrollToStart = () => {
  if (journeyCarousel.value) {
    journeyCarousel.value.scrollLeft = 0;
  }
};

const handleJourneyScroll = (event) => {
  const scrollPosition = event.target.scrollLeft;
  const itemWidth = event.target.offsetWidth;
  activeJourneyItem.value = Math.round(scrollPosition / itemWidth);
};

onMounted(() => {
  scrollToStart();
});



const faqs = ref([
        // Add your FAQ items here
        {
          title: "What is this website about?",
          description:
            "This website is a UK meal planner designed to help you organise your meals efficiently and healthily. It provides recipes, ingredient lists, and more to assist you in your meal planning journey. For those that love to cook.",
          open: false,
        },
        {
          title: "How do I use the meal planner?",
          description:
            "You can start by signing up for an account. Once logged in, navigate to the Meal Planner section where you can create your meal plans, and generate shopping lists.",
          open: false,
        },
        {
          title: "Can I customise my meal plans?",
          description:
            "Absolutely, customise your meal plans according to your dietary preferences and requirements.",
          open: false,
        },
        {
          title: "Is there a mobile app available?",
          description:
            "Currently, our meal planner is available as a web application. We are working on developing a mobile app to enhance your meal planning experience on the go.",
          open: false,
        },
        {
          title: "What about leftovers?",
          description:
            "This is taken into account, and tried to minimise during generation. But nevertheless there will always be leftovers, which we tell update in your pantry.",
          open: false,
        },
        {
          title: "How can I contact support?",
          description:
            "If you need assistance, you can contact our support team at info@mealmatcher.co.uk. We are here to help you with any questions or issues you may have.",
          open: false,
        },
])

onMounted(() => {
  nextTick(() => {
    hiddenElements.value = document.querySelectorAll('.hidden');
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      });
    });

    document.querySelectorAll('.timeline-item').forEach(el => observer.observe(el));
  });
  
  fetchMealPlanCount();
  setInterval(fetchMealPlanCount, 600000);
  
  const hiddenElement = document.querySelectorAll('.hi');
  hiddenElement.forEach((el) => observer.observe(el));
});
const calories = ref(2000);
const diet = ref('Any');
const shop = ref('Tesco');
const generatedMeals = ref(null);
const ingredients = ref([]);
const isButtonDisabled = ref(false);
const cooldownTime = ref(20);

const generateMeals = async () => {
  try {
    startCooldown();
    const response = await axios.post('api/generate_meal/', { 
      calories: calories.value, 
      diet: diet.value,
      shop: shop.value
    });
    generatedMeals.value = response.data.recipes;
    ingredients.value = response.data.ingredients;
  } catch (error) {
    console.error('Failed to generate meals', error);
  }
};

const totalMacros = computed(() => {
  if (!generatedMeals.value) return { calories: 0, protein: 0, fat: 0, carbs: 0 };
  return Object.values(generatedMeals.value).reduce((total, meal) => {
    total.calories += meal.calories;
    total.protein += meal.protien; // Note: API has a typo in 'protein'
    total.fat += meal.fat;
    total.carbs += meal.carbs;
    return total;
  }, { calories: 0, protein: 0, fat: 0, carbs: 0 });
});


const startPlanning = () => {
  if (authenticated){
    router.push("/uk-meal-planner")
  }else{
    router.push('/signup');
  }
};
const startCooldown = () => {
  isButtonDisabled.value = true;
  cooldownTime.value = 30;
  cooldownInterval = setInterval(() => {
    cooldownTime.value--;
    if (cooldownTime.value <= 0) {
      clearInterval(cooldownInterval);
      isButtonDisabled.value = false;
    }
  }, 1000);
}
const fetchMealPlanCount = async () => {
  try {
    const response = await axios.get(`api/meal-plan-count/`);
    mealPlanCount.value = response.data.count;
    animateCount(displayCount.value, mealPlanCount.value);
  } catch (error) {
    console.error('Failed to fetch meal plan count', error);
  }
};

const animateCount = (start, end) => {
  const duration = 1000;
  const difference = end - start;
  if (difference === 0) {
    return;
  }
  const stepTime = duration / difference;
  let currentCount = start;
  const timer = setInterval(() => {
    currentCount++;
    displayCount.value = currentCount;
    if (currentCount >= end) {
      clearInterval(timer);
    }
  }, stepTime);
};

</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@400;600;700&display=swap');
.timeline-item {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
}

.timeline-item.show {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.plans {
    position: relative;
  }
/* Inner shadow */
.head-shadow {
  text-shadow: -2px 4px 5px rgba(0, 0, 0, 0.3);
}

.shadow-inner-custom {
  box-shadow: inset 0 4px 8px rgba(255, 255, 255, 0.3);
}
.shadow-in-out {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3), inset 0 4px 8px rgba(255, 255, 255, 0.3);
}
/* Drop shadow */
.drop-shadow-custom {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}
.snap-x {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.snap-center {
  scroll-snap-align: center;
}
.container {
  max-width: 100%;
}
.hero, .features, .how-it-works, .testimonials, .call-to-action {
  text-align: center;
}
.feature {
  display: inline-block;
  margin: 20px;
}
footer {
  text-align: center;
  padding: 20px;
}
button {
  transition: all 0.3s;
}
.parallax-image {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
  will-change: transform;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-fade-in-up {
  animation: fadeInUp 1s ease-out;
}

.animate-fade-in-down {
  animation: fadeInDown 1s ease-out;
}@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.hero-section {
  min-height: 100vh;
}

.left-decor, .right-decor {
  pointer-events: none;
}

.food-image-1, .food-image-2 {
  width: 80%;
  overflow: hidden;
}

.testimonial-bubble {
  max-width: 150px;
}

.icon-1, .icon-2 {
  opacity: 0.7;
}

.animated-ingredient {
  opacity: 0.8;
}
.phone-carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 1rem 0;
  }

  .phone-carousel::-webkit-scrollbar {
    display: none;
  }

  .phone-container {
    flex: 0 0 80%;
    scroll-snap-align: center;
    margin-right: 1rem;
    max-width: 300px; /* Add this line to limit max width */
  }


  .phone-frame {
    position: relative;
    width: 100%;
    padding-bottom: 200%; /* This creates a 1:2 aspect ratio */
    background: #000;
    border-radius: 36px;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .phone-screen {
    position: absolute;
    top: 2%;
    left: 2%;
    width: 96%;
    height: 96%;
    object-fit: cover;
    border-radius: 32px;
  }

  @media (min-width: 768px) {
    .phone-carousel {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 2rem;
      justify-content: center;
    }

    .phone-container {
      flex: none;
      margin-right: 0;
      width: 80%;
      max-width: 250px; /* Adjust this value as needed */
      justify-self: center;
    }

    .phone-frame {
      padding-bottom: 200%; /* Keep the same aspect ratio */
    }
  }

  @media (min-width: 1024px) {
    .phone-container {
      max-width: 220px; /* Further reduce size on larger screens */
    }
  }
  .parallax-circle {
  transition: transform 0.1s ease-out;
}
.relative {
  position: relative;
}

.algorithm-image {
  object-fit: contain;
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.phone-slider {
  width: 48px; /* Show a small part of the phone */
  overflow: hidden;
  cursor: pointer;
}

@media (min-width: 768px) {
  .phone-slider {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden;
}
.bg-grid-pattern {
  background-image: linear-gradient(to right, #8585851a 1px, transparent 1px),
                    linear-gradient(to bottom, #74747417 1px, transparent 1px);
  background-size: 35px 35px; /* Adjust the size of the grid */
  min-height: 100vh; /* Full screen height */
  z-index: 10;
}
.hero::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px; /* Adjust height for curve depth */
    background: #ffffff00; /* No background color */
    border-radius: 100% 100% 0 0 / 100%; /* Create the curve */
    transform: translateY(50%);
    z-index: 10;
    pointer-events: none; /* Ensure it doesn't interfere with interactions */
}
@keyframes loopArrow {
  0%, 100% { transform: translateX(0) rotate(0deg); }
  50% { transform: translateX(10px) rotate(10deg); }
}
.animate-bounce {
  animation: loopArrow 2s infinite;
}
.scrolling-banner {
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

/* Pause animation on hover */
.scrolling-banner:hover {
  animation-play-state: paused;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
