<template>
    <div >
      
      <!-- Desktop Sidebar -->
      <div class="hidden md:block  h-full w-screen bg-[#3a7160]    z-50 overflow-y-auto">
        <div class=" w-screen  z-50 ">
          <SidebarContentDesktop  />
        </div>
      </div>
  
       <!-- Mobile Top Bar -->
    <div class="md:hidden block w-full h-14 bg-[#3a7160]  z-50 flex justify-between items-center px-4 shadow-sm">
      <router-link to="/"  @click="emitLinkClicked">
        <img src="../assets/logo4.png" alt="Logo" class="h-8 w-8 object-contain">
        
      </router-link>
      <router-link to="/"  @click="emitLinkClicked">
        <h2 class="text-2xl font-thin text-white">Meal Matcher</h2>
      </router-link>
      
      <!-- Toggle button -->
      <button 
        @click="toggleSidebar" 
        class="z-50 p-2 text-lg text-white rounded-full hover:bg-gray-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-700"
      >
        <i :class="['fas', isOpen ? 'fa-times' : 'fa-bars']"></i>
      </button>
    </div>

    <!-- Mobile Sidebar with smooth slide animation -->
    <transition
      enter-active-class="transition-transform duration-500 ease-out"
      leave-active-class="transition-transform duration-300 ease-in"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <div
        v-show="isOpen"
        class="fixed inset-y-0 left-0 h-screen w-64 bg-[#3a7160] border-r border-gray-700 shadow-lg z-50"
      >
        <div class="py-4 h-full overflow-y-auto">
          <SidebarContent @linkClicked="closeSidebar" />
        </div>
      </div>
    </transition>

    <!-- Overlay with gradual darkening -->
    <transition
      enter-active-class="transition-opacity duration-500 ease-out"
      leave-active-class="transition-opacity duration-300 ease-in"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div 
        v-if="isOpen" 
        class="fixed inset-0 bg-black bg-opacity-50 z-40 overlay-fade h-screen" 
        @click="closeSidebar"
      ></div>
    </transition>
  </div>
</template>
  
  <script setup>
  import { ref } from 'vue';

  import SidebarContent from './SidebarContent.vue';
  import SidebarContentDesktop from './SidebarContentDesktop.vue';
 
  const isOpen = ref(false);
  
  const toggleSidebar = () => {
    isOpen.value = !isOpen.value;
  };
  
  const closeSidebar = () => {
    isOpen.value = false;
  };
  </script>
  
  <style scoped>
  @import '@fortawesome/fontawesome-free/css/all.css';
  .icon-icon-hamburger-menu-thin {
  background-image: url('../assets/icons/hamburger-icon.svg');
}

  </style>