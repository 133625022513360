<template>
    <!-- Desktop Navbar -->
    <div class="bg-[#3a7160] text-white h-20 flex items-center justify-between px-8 shadow-md">
      <!-- Left Side: Logo and Title -->
      <div class="flex items-center space-x-4">
        <router-link to="/" class="flex items-center" @click="emitLinkClicked">
          <img src="../assets/logo4.png" alt="Logo" class="h-12 w-12 object-contain">
          <h2 class="text-2xl font-medium ml-2">Meal Matcher</h2>
        </router-link>
      </div>
  
      <!-- Navigation Links -->
      <nav class="flex space-x-6 font-button">
        <router-link
          v-for="link in navLinks"
          :key="link.to"
          :to="link.to"
          class="relative text-lg font-light text-gray-100 hover:text-white transition-colors"
          :class="{ 'active text-white font-medium': $route.path === link.to }"
          @click="emitLinkClicked"
        >
          {{ link.text }}
          <span
            v-if="$route.path === link.to"
            class="absolute bottom-[-8px] left-0 w-full h-1 bg-white rounded"
          ></span>
        </router-link>
      </nav>
  
      <!-- Right Side: Authentication Links -->
      <div class="flex items-center space-x-6">
        <template v-if="!authenticated">
          <router-link
            to="/login"
            class="bg-gray-600 py-2 px-4 rounded-full hover:bg-gray-500 transition-colors"
            @click="emitLinkClicked"
          >
            Log in
          </router-link>
          <router-link
            to="/signup"
            class="bg-interactive py-2 px-4 rounded-full hover:bg-interactive-hover transition-colors"
            @click="emitLinkClicked"
          >
            Get started
          </router-link>
        </template>
        <template v-else>
          <router-link
            to="/profile"
            class="flex items-center bg-gray-100 text-gray-800 py-2 px-4 rounded-full hover:bg-gray-200 transition-colors"
            @click="emitLinkClicked"
          >
            <ion-icon name="person-circle-outline" class="text-2xl mr-2"></ion-icon>
            {{ user.username }}
          </router-link>
          <button
            @click="logout"
            class="bg-gray-600 py-2 px-4 rounded-full hover:bg-gray-500 transition-colors"
          >
            Logout
          </button>
        </template>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  
  const store = useStore();
  const router = useRouter();
  
  const authenticated = computed(() => store.state.authenticated);
  const user = computed(() => store.state.user);
  
  const navLinks = [
    { to: '/uk-meal-planner', text: 'Meal Planner' },
    { to: '/shopping-list', text: 'Shopping List' },
    { to: '/leftovers', text: 'Use Leftovers' },
    { to: '/recipes', text: 'Recipes' },
    { to: '/about', text: 'About' },
  ];
  
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    store.dispatch('logout');
    router.push('/');
    emitLinkClicked();
  };
  
  const emitLinkClicked = () => {
    emit('linkClicked');
  };
  
  const emit = defineEmits(['linkClicked']);
  </script>
  
  <style scoped>
  .active {
    position: relative;
  }
  
  nav a:hover {
    color: #d1d5db; /* Tailwind's gray-300 */
  }
    </style>