<template >
  <div v-if="showRecipeForm" class="w-full max-w-screen-2xl">
    <RecipeCreateEdit 
      :recipe="editingRecipe"
      :cuisines="cuisines"
      
      :availableIngredients="availableIngredients"
      
      @close="showRecipeForm = false, editingRecipe = null;"
      @recipe-saved="fetchRecipes"
    />
  </div>
  <div v-else-if="showRecipe" class="w-full max-w-screen-2xl">
    <RecipeModel :recipe="selectedRecipe" :portions="selectedPortions" @close="showRecipe = false" />
  </div>

  <div v-else class="relative pb-8 w-full mx-auto px-4 md:px-8  max-w-screen-2xl"
  :class="{'pt:0': useIngredients, 'py-8': !useIngredients}">
  <!-- Translucent overlay -->  
  <div v-if="(useIngredients && !canSearch) || (useIngredients && isFirstIngredientSearch)" 
         class="absolute inset-0 mt-28 bg-gray-900 bg-opacity-85 z-10 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg shadow-lg text-center">
        <p v-if="!canSearch"class="text-xl font-bold text-gray-800 mb-4">Please Select Portions and Store</p>
        <p v-if="!canSearch" class="text-gray-600">You need to choose portions and store to view recipes.</p>
        <p v-if="canSearch" class="text-gray-600">Performing calculations...</p>

      </div>
    </div>
    <!-- Selection Section for 'useIngredients' Mode -->
    <div v-if="useIngredients" class="flex items-center justify-center p-4 bg-gray-100 border-b-2 border-gray-300 sm:space-x-4">

    <!-- Portions Selector -->
    <div class="flex flex-col items-center p-2">
      <label for="portions" class="block text-sm font-medium text-gray-700 mb-1 text-center">Portions</label>
      <select 
        id="portions" 
        v-model="selectedPortions" 
        @change="updatePortions(selectedPortions)"
        :disabled="canSearch && isFirstIngredientSearch"
        class="focus:ring-interactive focus:border-interactive block w-20 pl-3 pr-10 sm:text-sm border-gray-300 rounded-md"
      >
        <option v-for="n in 4" :key="n" :value="n">{{ n }}</option>
      </select>
    </div>

    <!-- Store Selector -->
    <div class="flex flex-col items-center p-2">
      <label for="store" class="block text-sm font-medium text-gray-700 mb-1 text-center">Store</label>
      <select 
        id="store" 
        v-model="selectedStore" 
        @change="updateStore(selectedStore)"
        :disabled="canSearch && isFirstIngredientSearch"
        class="focus:ring-interactive focus:border-interactive block w-36 pr-10 sm:text-sm border-gray-300 rounded-md"
      >
        <option value="Tesco">Tesco</option>
        <option value="Sainsburys">Sainsbury's</option>
        <option value="Aldi">Aldi</option>
        <option value="MandS">M&S</option>
      </select>
    </div>

    <!-- Include Spices Selector -->
    <div class="flex flex-col items-center p-2">
      <label for="includeSpices" class="block text-sm font-medium text-gray-700 mb-1 text-center">Include Seasonings</label>
      <select 
        id="seasonings" 
        v-model="selectedSeasonings" 
        @change="updateIncludeSpices(selectedSeasonings)"
        :disabled="canSearch && isFirstIngredientSearch"
        class="focus:ring-interactive focus:border-interactive block w-36 pr-10 sm:text-sm border-gray-300 rounded-md"
      >
        <option value="False">No</option>
        <option value="True">Yes</option>
      </select>
    </div>

</div>
    <div class="mb-2 md:mb-4 p-4 pt-2">
      <div class="px-2 py-2">
        <div class="flex flex-col md:flex-row items-center justify-between gap-4 ">
          <div class="hidden md:flex  md:w-fit space-x-3 items-center justify-between md:justify-start">
            <h1 v-if="!useIngredients" class="text-2xl md: text-3xl font-semibold text-gray-900">Recipes</h1>
            <svg v-if="!useIngredients" class="w-8 h-8 text-gray-900 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
            </svg>
          </div>
          <div class="flex items-center space-x-4 flex-grow md:flex-grow-0 w-full "
              :class="{'w-full': useIngredients, 'md:w-1/2': !useIngredients}">
            <div class="relative w-full  text-gray-500">
              <input 
                v-model="searchQuery" 
                @keyup.enter="performSearch"
                placeholder="Start Searching Recipes..." 
                class="w-full drop-shadow rounded-full py-4 pl-10 pr-4 focus:outline-none focus:ring-2 border border-1 border-gray-500 focus:border-interactive  focus:ring-interactive focus:ring-opacity-50 transition duration-150 ease-in-out"
              />
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg class="w-5 h-5 text-gray-500" :class="{ 'text-interactive': searchQuery }" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </div>
              
          </div>
          <button
              v-if="authenticated && !useIngredients"
              @click="showRecipeForm = true"
              :disabled="!isFormReady"
              :class="{'opacity-50 cursor-not-allowed': !isFormReady}"
              class="md:hidden bg-interactive text-white px-1 py-1 rounded-full hover:bg-opacity-90  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out flex items-center"
                >
              <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
              </svg>
            </button>
          </div>
          <div class="flex justify-between items-center">
            <button
              v-if="authenticated && !useIngredients"
              @click="showRecipeForm = true"
              :disabled="!isFormReady"
              :class="{'opacity-100 cursor-not-allowed': !isFormReady}"
              class="hidden md:flex bg-interactive text-white px-3 py-2 rounded-full hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out flex items-center"
            >
              <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
              </svg>
              Create Recipe
            </button>
          </div>
        </div>
         <!-- Active filters -->
      <div v-if="activeFilters.length > 0" class="mt-4 flex flex-wrap gap-2">
        <div v-for="filter in activeFilters" :key="filter.type + filter.value" 
             class="bg-interactive text-white px-3 py-1 rounded-full text-sm flex items-center">
          {{ filter.value }}
          <button @click="removeFilter(filter)" class="ml-2 focus:outline-none">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      </div>
      </div>
    </div>
    <div class="flex flex-col-reverse md:flex-row w-full justify-center md:justify-between items-center mt-4 ">
          <!-- Toggle for All Recipes / My Recipes -->
          <div class="flex  rounded-lg p-1 m-1">
            <button 
              @click="setActiveFilter('all')" 
              class="px-4 py-2 rounded-md text-sm font-medium focus:outline-none transition-colors duration-200 ease-in-out"
              :class="{ 'bg-white text-gray-800 shadow': activeFilter === 'all', 'text-gray-600 hover:text-gray-800': activeFilter !== 'all' }"
            >
            Public Recipes
            </button>
            <button 
              v-if="authenticated"
              @click="setActiveFilter('user')" 
              class="px-4 py-2 rounded-md text-sm font-medium focus:outline-none transition-colors duration-200 ease-in-out"
              :class="{ 'bg-white text-gray-800 shadow': activeFilter === 'user', 'text-gray-600 hover:text-gray-800': activeFilter !== 'user' }"
            >
              My Recipes
            </button>
            <button 
              v-if="authenticated"
              @click="setActiveFilter('fav')" 
              class="px-4 py-2 rounded-md text-sm font-medium focus:outline-none transition-colors duration-200 ease-in-out"
              :class="{ 'bg-white text-gray-800 shadow': activeFilter === 'fav', 'text-gray-600 hover:text-gray-800': activeFilter !== 'fav' }"
            >
              Favorites
            </button>
            
          </div>
          <div class="flex w-full justify-between md:justify-center md:w-fit items-center space-x-12">
            <!-- Sort dropdown -->
            <div class="relative inline-block text-left" ref="sortDropdownRef">
              <button
                @click="toggleSortDropdown"
                class="py-2 rounded-full text-md font-medium text-gray-700 flex items-center transition-colors duration-300 ease-in-out"
              >
                <span class="text-gray-700">Sort by:&nbsp;&nbsp;</span> {{ getSortLabel() }}
                <svg class="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                </svg>
              </button>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
                >
                <div v-if="showSortDropdown" class="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg z-10 ring-1 ring-black bg-opacity-100 bg-white ring-opacity-10 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="py-1" role="none">
                    <a v-if="useIngredients" @click="selectSort('cost')" class="text-gray-700 block px-4 py-2 bg-opacity-100 bg-white text-sm hover:bg-gray-100" role="menuitem" tabindex="-1">Cost</a>
                    <a @click="selectSort('name')" class="text-gray-700 block px-4 py-2 bg-opacity-100 bg-white text-sm hover:bg-gray-100" role="menuitem" tabindex="-1">Name (A-Z)</a>
                    <a @click="selectSort('-name')" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabindex="-1">Name (Z-A)</a>
                    <a @click="selectSort('-created_at')" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabindex="-1">Newest</a>
                    <a @click="selectSort('created_at')" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabindex="-1">Oldest</a>
                    <a @click="selectSort('avg_rating')" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabindex="-1">Rating (High to Low)</a>
                  </div>
                </div>
              </transition>
            </div>
            <div class="flex items-center space-x-4">
              <!-- Filter button and dropdown -->
              <div class="relative inline-block text-left" ref="filterDropdownRef">
                <button @click="toggleFilterDropdown" class="flex items-center text-gray-600 hover:text-interactive transition-colors duration-200">
                  <svg class="w-6 h-6 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6H20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 12H14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 18H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <circle cx="17" cy="12" r="2" fill="currentColor"/>
                    <circle cx="10" cy="18" r="2" fill="currentColor"/>
                  </svg>
                  Filter
                </button>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <div v-if="showFilterDropdown" 
                      class="origin-top-right absolute right-0 left-0 mt-2 ml-24 md:ml-0  w-screen md:w-[32rem] md:max-w-lg lg:max-w-2xl rounded-md shadow-lg z-20 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" 
                      role="menu" 
                      aria-orientation="vertical" 
                      aria-labelledby="filter-button" 
                      tabindex="-1"
                      :style="{ left: filterDropdownPosition.left, right: filterDropdownPosition.right }">
                    <div class="p-4 md:p-6">
                      <h2 class="text-xl font-bold mb-4">Filter Recipes</h2>
                      <div class="grid grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6">
                        <!-- Diet filters -->
                        <div>
                          <h3 class="font-semibold mb-2">Diet</h3>
                          <div class="space-y-2">
                            <button 
                              v-for="diet in dietList" 
                              :key="diet" 
                              @click="toggleFilter('diet', diet)"
                              class="w-full text-left px-2 py-1 rounded-md text-sm hover:bg-gray-100 transition-colors duration-200"
                              :class="{'bg-interactive text-white hover:bg-interactive-hover': selectedFilters.diet.includes(diet)}"
                            >
                              {{ diet }}
                            </button>
                          </div>
                        </div>
                        
                        <!-- Cuisine filters -->
                        <div>
                          <h3 class="font-semibold mb-2">Cuisine</h3>
                          <div class="space-y-2">
                            <button 
                              v-for="cuisine in cuisineList" 
                              :key="cuisine" 
                              @click="toggleFilter('cuisine', cuisine)"
                              class="w-full text-left px-2 py-1 rounded-md text-sm hover:bg-gray-100 transition-colors duration-200"
                              :class="{'bg-interactive text-white hover:bg-interactive-hover': selectedFilters.cuisine.includes(cuisine)}"
                            >
                              {{ cuisine }}
                            </button>
                          </div>
                        </div>
                        
                        <!-- Meal type filters -->
                        <div>
                          <h3 class="font-semibold mb-2">Meal Type</h3>
                          <div class="space-y-2">
                            <button 
                              v-for="mealType in mealTypeList" 
                              :key="mealType" 
                              @click="toggleFilter('mealType', mealType)"
                              class="w-full text-left px-2 py-1 rounded-md text-sm hover:bg-gray-100 transition-colors duration-200"
                              :class="{'bg-interactive text-white hover:bg-interactive-hover': selectedFilters.mealType.includes(mealType)}"
                            >
                              {{ mealType }}
                            </button>
                          </div>
                        </div>
                        
                        <!-- Time filters -->
                        <div>
                          <h3 class="font-semibold mb-2">Preparation Time</h3>
                          <div class="space-y-2">
                            <button 
                              v-for="time in timeList" 
                              :key="time" 
                              @click="toggleFilter('time', time)"
                              class="w-full text-left px-2 py-1 rounded-md text-sm hover:bg-gray-100 transition-colors duration-200"
                              :class="{'bg-interactive text-white hover:bg-interactive-hover': selectedFilters.time.includes(time)}"
                            >
                              {{ time }}
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      <div class="mt-6 flex justify-end space-x-4">
                        <button @click="clearFilters" class="px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100 transition-colors duration-200">
                          Clear All
                        </button>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          
        </div>
      <!-- Recipe Grid -->
    <div v-if="recipes.length" class="space-y-8 ">
      <div>
        
        <div class="grid grid-cols-1  md:gap-8"
        :class="{'lg:grid-cols-2 2xl:grid-cols-3': useIngredients, 'lg:grid-cols-3 2xl:grid-cols-4': !useIngredients}">
          <div v-for="recipe in recipes" :key="recipe.id"
            @click="viewRecipe(recipe)"
            class="w-full flex md:flex-col bg-white rounded-lg drop-shadow overflow-hidden border border-gray-200 transition duration-300 ease-in-out hover:shadow-lg  cursor-pointer group"
            >
            <!-- Edit button (blue pencil icon) -->
            <button v-if="isStaff"  @click.stop="changePhoto(recipe)"  class="absolute top-2 left-2 bg-pink-600 hover:bg-pink-700 text-white rounded-full p-2 transition duration-300 ease-in-out z-10">
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
              </svg>
            </button>
            <div class="w-1/5 md:w-auto relative h-48 overflow-hidden">
              <img 
                :src="getOptimizedImageUrl(recipe.image)" 
                :alt="recipe.name" 
                class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
              />
              <div class="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-opacity duration-300"></div>
              
            </div>
            <div class="p-4 w-4/5 md:w-full">
              <!-- User-specific toggle switch -->
              <div v-if="!useIngredients" class="flex items-center w-full justify-between mx-auto">
                <label :for="'recipe-toggle-' + recipe.id" class="flex items-center cursor-pointer">
                  <div @click.stop class="relative">
                    <input 
                      type="checkbox" 
                      :id="'recipe-toggle-' + recipe.id" 
                      class="sr-only" 
                      v-model="recipe.userEnabled" 
                      @change="enabledToggle(recipe)"
                    >
                    <div 
                      class="w-8 h-4 rounded-full shadow-inner transition-colors duration-200 ease-in-out"
                      :class="{ 'bg-green-500': recipe.userEnabled, 'bg-red-500': !recipe.userEnabled }"
                    ></div>
                    <div 
                      class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition-transform duration-200 ease-in-out"
                      :class="{ 'translate-x-full': recipe.userEnabled, '': !recipe.userEnabled }"
                    ></div>
                  </div>
                  <span class="ml-4 text-sm text-gray-600">
                    {{ recipe.userEnabled ? 'Enabled' : 'Disabled' }}
                  </span>
                </label>
                <!-- Approval status and actions -->
                <div v-if="recipe.is_owner" class="text-end">
                  <p class="text-xs" :class="{
                    'hidden': recipe.approval_status === 'not_submitted',
                    'text-blue-600': recipe.approval_status === 'pending',
                    'text-green-600': recipe.approval_status === 'approved',
                    'text-red-600': recipe.approval_status === 'rejected'
                  }">
                    Status: {{ getApprovalStatusText(recipe.approval_status) }}
                  </p>
                  <button 
                    v-if="recipe.approval_status === 'not_submitted'" 
                    @click.stop="submitForApproval(recipe)"
                    class="px-2 py-1 bg-interactive text-white rounded-full text-xs transition duration-300"
                  >
                    Publish
                  </button>
                </div>
              </div>
              
              <h3 class="font-semibold text-md text-gray-800 ">{{ recipe.name }}</h3>
              <p class="text-sm text-gray-500 mb-2">By {{ recipe.owner_username }}</p>
               <!-- Cost and Availability Section for 'useIngredients' Mode -->
               <div v-if="useIngredients">
                <!-- If recipe cannot be made from the store -->
                <div v-if="recipe.cost >= 1000" class="mt-2 p-4 rounded-lg bg-black text-white">
                  <p>Cannot be made from this store.</p>
                </div>

                <!-- If recipe is ready to cook -->
                <div v-else-if="recipe.cost === 0" class="mt-2 p-3 rounded-lg shadow-sm transition-all duration-300 ease-in-out bg-emerald-50">
                  <div class="flex items-center justify-between">
                    <span class="text-emerald-700 font-semibold">Ready to Cook</span>
                    <button class="bg-emerald-600 hover:bg-emerald-700 text-white font-medium py-1 px-3 rounded-full text-sm transition-colors duration-200 ease-in-out transform hover:scale-105">
                      Start Cooking
                    </button>
                  </div>
                </div>

                <!-- If ingredients are needed -->
                <div v-else class="mt-2 p-3 rounded-lg shadow-sm transition-all duration-300 ease-in-out bg-amber-50">
                  <div class="flex items-center justify-between">
                    <span class="text-amber-700 font-semibold">Ingredients Needed</span>
                    <div class="text-right">
                      <p class="text-amber-600 text-sm">
                        Missing: {{ recipe.missingIngredients.length }}
                      </p>
                      <p class="text-amber-800 font-medium">
                        £{{ recipe.cost.toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-center mb-2">
                
                <div class="flex items-center">
                  <template v-for="n in 5" :key="n">
                    <svg class="w-4 h-4 mr-1" :class="{'text-yellow-400': n <= Math.round(recipe.average_rating), 'text-gray-300': n > Math.round(recipe.average_rating)}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </template>
                </div>
                <span class="text-sm font-medium text-gray-600 ml-1">
                  {{ recipe.average_rating.toFixed(1) }} ({{ recipe.ratings.length }})
                </span>
              </div>
              <div class="flex flex-wrap text-sm text-gray-600 mb-2">
                <span class="mr-3 mb-1"><i class="text-interactive fas fa-globe-americas mr-1"></i>{{ recipe.cuisine.name }}</span>
                <span class="hidden md:block mr-3 mb-1"><i class="text-interactive fas fa-clock mr-1"></i>{{ recipe.prep_time + recipe.cook_time }} mins</span>
                <span class="hidden md:block truncate"><i class=" text-interactive fas fa-signal mr-1"></i>{{ recipe.complexity }}</span>
              </div>
              <div v-if="!useIngredients" class="flex space-x-2">
                <button 
                  v-if="recipe.is_owner || isStaff " 
                  :disabled="!isFormReady"  
                  @click="editRecipe(recipe)" 
                  :class="{'opacity-50 cursor-not-allowed': !isFormReady}"
                  class="flex-none bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition duration-300 ease-in-out flex items-center justify-center"
                >
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                  </svg>
                  <span class="ml-2">Edit</span>
                </button>
              </div>
              
              <!-- Staff approval actions -->
            <div v-if="isStaff && recipe.approval_status === 'pending' && !useIngredients" class="mt-2">
              <button 
                @click.stop="approveRejectRecipe(recipe, 'approved')"
                class="mr-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300"
              >
                Approve
              </button>
              <button 
                @click.stop="approveRejectRecipe(recipe, 'rejected')"
                class="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
              >
                Reject
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Recipe Grid or Loading States -->
    <div v-if="canSearch" class="space-y-8">
      <div v-if="loading" class="text-center mt-10">
        <p v-if="isFirstIngredientSearch" class="text-stone-500 font-serif">
          Calculating costs... This may take up to 30 seconds.
        </p>
        <p v-else class="text-stone-500 font-serif">Preparing the Recipes...</p>
      </div>
      <div v-else-if="recipes.length" class="grid grid-cols-1 md:gap-8 lg:grid-cols-3 2xl:grid-cols-4">
        <!-- ... (existing recipe card code) ... -->
      </div>
      <p v-else class="text-center text-stone-500 mt-10 font-serif italic">No recipes available at the moment.</p>
    </div>
    
    <div v-else class="text-center mt-10">
      <p class="text-blue-600 font-bold text-xl">Please select portions and store to view recipes.</p>
    </div>

    <!-- Load More Button -->
    <div v-if="hasMoreRecipes && !loading && canSearch" class="text-center mt-8 pb-4">
      <button @click="loadMoreRecipes" class="bg-interactive hover:bg-interactive-hover text-white font-semibold py-3 px-6 rounded-md shadow transition duration-300 ease-in-out">
        Discover More Recipes
      </button>
    </div>
    
    <!-- No More Recipes Indicator -->
    <p v-if="!hasMoreRecipes && recipes.length > 0" class="text-center text-stone-500 mt-10 font-serif italic">You've explored all our culinary creations.</p>

    <div v-if="isStaff" class="fixed bottom-24 right-8 space-y-4">
      <button 
        @click="generateStats"
        class="bg-interactive hover:bg-interactive-hover text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out block"
      >
        Generate Stats
      </button>
      <button 
        @click="toggleIngredientsModal"
        class="bg-interactive hover:bg-interactive-hover text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out block"
      >
        Manage Ingredients
      </button>
    </div>
    <IngredientsModal 
      v-if="showIngredientsModal"
      :ingredients="availableIngredients"
      @close="toggleIngredientsModal"
      class="z-40 ml-60"
    />
    <!-- Stats Modal -->
    <div v-if="showStatsModal" class="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
    <div class="bg-white rounded-lg shadow-2xl w-full max-w-6xl max-h-[90vh] overflow-y-auto flex flex-col p-6">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-serif font-bold text-stone-800">Recipe Statistics</h2>
        <button @click="closeStatsModal" class="text-gray-500 hover:text-gray-700">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <div v-if="recipeStats" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div class="stats-section">
          <h3 class="text-xl font-semibold mb-2 text-interactive">Cuisine Stats</h3>
          <ul class="space-y-1">
            <li v-for="stat in recipeStats.cuisine_stats" :key="stat.cuisine__name" class="flex justify-between">
              <span>{{ stat.cuisine__name }}</span>
              <span class="font-medium">{{ stat.count }} recipes</span>
            </li>
          </ul>
        </div>
        <div class="stats-section">
          <h3 class="text-xl font-semibold mb-2 text-interactive">Complexity Stats</h3>
          <ul class="space-y-1">
            <li v-for="stat in recipeStats.complexity_stats" :key="stat.complexity" class="flex justify-between">
              <span>{{ stat.complexity }}</span>
              <span class="font-medium">{{ stat.count }} recipes</span>
            </li>
          </ul>
        </div>
        <div class="stats-section">
          <h3 class="text-xl font-semibold mb-2 text-interactive">Diet Stats</h3>
          <ul class="space-y-1">
            <li v-for="stat in recipeStats.diet_stats" :key="stat.name" class="flex justify-between">
              <span>{{ stat.name }}</span>
              <span class="font-medium">{{ stat.count }} recipes</span>
            </li>
          </ul>
        </div>
        <div class="stats-section">
          <h3 class="text-xl font-semibold mb-2 text-interactive">Store Stats</h3>
          <ul class="space-y-1">
            <li v-for="stat in recipeStats.store_stats" :key="stat.name" class="flex justify-between">
              <span>{{ stat.name }}</span>
              <span class="font-medium">{{ stat.count }} recipes</span>
            </li>
          </ul>
        </div>
        <div class="stats-section">
          <h3 class="text-xl font-semibold mb-2 text-interactive">Meal Type Stats</h3>
          <ul class="space-y-1">
            <li v-for="stat in recipeStats.meal_type_stats" :key="stat.name" class="flex justify-between">
              <span>{{ stat.name }}</span>
              <span class="font-medium">{{ stat.count }} recipes</span>
            </li>
          </ul>
        </div>
        <div class="stats-section">
          <h3 class="text-xl font-semibold mb-2 text-interactive">Top Ingredients</h3>
          <ul class="space-y-1">
            <li v-for="stat in sortedIngredients" :key="stat.ingredient__name" class="flex justify-between">
              <span>{{ stat.ingredient__name }}</span>
              <span class="font-medium">{{ stat.count }} recipes</span>
            </li>
          </ul>
        </div>
      </div>
      
      <div class="mt-6">
        <h3 class="text-2xl font-semibold mb-4 text-interactive">Missing Ingredients Analysis</h3>
        <div v-for="(store, storeName) in recipeStats.missing_ingredients" :key="storeName" class="mb-6">
          <h4 class="text-xl font-semibold mb-2">{{ storeName }}</h4>
          <p class="mb-2">Available Recipes: {{ recipeStats.recipes_count[storeName] }}</p>
          <div class="overflow-x-auto">
            <table class="min-w-full bg-white">
              <thead class="bg-gray-100">
                <tr>
                  <th class="px-4 py-2 text-left">Ingredient</th>
                  <th class="px-4 py-2 text-left">Missing Count</th>
                  <th class="px-4 py-2 text-left">Impact (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ingredient in store" :key="ingredient.name" class="border-b">
                  <td class="px-4 py-2">{{ ingredient.name }}</td>
                  <td class="px-4 py-2">{{ ingredient.missing_count }}</td>
                  <td class="px-4 py-2">{{ ingredient.impact }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <button @click="closeStatsModal" class="mt-6 bg-interactive hover:bg-interactive-hover text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out self-end">
        Close
      </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, defineProps, onUnmounted, nextTick  } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

import RecipeFilter from './RecipeFilter.vue';
import debounce from 'lodash/debounce';
import IngredientsModal from '../manager_stuff/IngredientList.vue';
import RecipeCreateEdit from './RecipeCreateEdit.vue';
import RecipeModel from './RecipeModel.vue';


const props = defineProps({
  useIngredients: {type: Boolean,
    default: false
  },
});
const cuisines = ref([]);
const showSortDropdown = ref(false);
const authenticated = computed(() => store.state.authenticated);
const showRecipeForm = ref(false);
const showRecipe = ref(false);
const editingRecipe = ref(null);
const showIngredientsModal = ref(false);
const isStaff = computed(() => store.state.user && store.state.user.is_staff);
const store = useStore();
const recipes = ref([]);
const activeFilter = ref('all');
const isSearching = ref(false);
const filters = ref({ mealTypes: [], cuisine: [] });
const page = ref(1);
const loading = ref(false);
const hasMoreRecipes = ref(true);
const itemsPerPage = 12;
const searchQuery = ref('');
const sortOrder = ref('');
const useIngredients = ref(props.useIngredients);
const selectedRecipe = ref(null);
const selectedPortions = ref(null);
const selectedStore = ref(null);
const selectedSeasonings = ref(null);
const isFirstIngredientSearch = ref(false);
const canSearch = computed(() => {
  return !props.useIngredients || (selectedPortions.value && selectedStore.value &&  selectedSeasonings.value);
});
const updatePortions = (newPortions) => {
  isFirstIngredientSearch.value = true;
  if (canSearch.value) fetchRecipes(true, useIngredients.value);
};

const updateStore = (newStore) => {
  isFirstIngredientSearch.value = true;
  if (canSearch.value) fetchRecipes(true, useIngredients.value);
};
const updateIncludeSpices = (newSeasonings) => {
  isFirstIngredientSearch.value = true;
  if (canSearch.value) fetchRecipes(true, useIngredients.value);
};

const viewRecipe = (recipe) => {
  selectedRecipe.value = recipe;
  showRecipe.value = true;
};
const filterDropdownPosition = ref({ left: 'auto', right: '0' });

const toggleIngredientsModal = () => {
  showIngredientsModal.value = !showIngredientsModal.value;
};
const filterDropdownRef = ref(null);
const sortDropdownRef = ref(null);


const toggleFilter = (type, value) => {
  const index = selectedFilters.value[type].indexOf(value);
  if (index === -1) {
    selectedFilters.value[type].push(value);
  } else {
    selectedFilters.value[type].splice(index, 1);
  }
};
const toggleSortDropdown = () => {
  showSortDropdown.value = !showSortDropdown.value;
  if (showSortDropdown.value) {
    showFilterDropdown.value = false;
  }
};
const toggleFilterDropdown = () => {
  showFilterDropdown.value = !showFilterDropdown.value;
  if (showFilterDropdown.value) {
    showSortDropdown.value = false;
  }
  if (showFilterDropdown.value) {
    nextTick(() => {
      positionFilterDropdown();
    });
  }
};
const closeDropdowns = (event) => {
  if (filterDropdownRef.value && !filterDropdownRef.value.contains(event.target)) {
    showFilterDropdown.value = false;
  }
  if (sortDropdownRef.value && !sortDropdownRef.value.contains(event.target)) {
    showSortDropdown.value = false;
  }
};
const positionFilterDropdown = () => {
};

onMounted(() => {
  window.addEventListener('resize', positionFilterDropdown);
});

onUnmounted(() => {
  window.removeEventListener('resize', positionFilterDropdown);
});
onMounted(() => {
  document.addEventListener('click', closeDropdowns);
});

onUnmounted(() => {
  document.removeEventListener('click', closeDropdowns);
});
const selectSort = (value) => {
  sortOrder.value = value;
  showSortDropdown.value = false;
  fetchRecipes(true, useIngredients.value);
};
const getSortLabel = () => {
  const sortLabels = {
    'cost': 'Cost',
    'name': 'Name (A-Z)',
    '-name': 'Name (Z-A)',
    '-created_at': 'Newest',
    'created_at': 'Oldest',
    'avg_rating': 'Rating (High to Low)'
  };
  return sortLabels[sortOrder.value] || 'Select';
};
const availableIngredients = ref([]); 
const dietList = ref(['Vegetarian', 'Vegan', 'Gluten-Free', 'Keto', 'Paleo', 'Dairy Free', 'Atkins', 'High Protein']);
const cuisineList = ref(['British', 'Italian', 'Mexican', 'Chinese', 'Indian', 'Japanese', 'Spanish', 'Thai']);
const mealTypeList = ref(['Breakfast', 'Lunch', 'Dinner', 'Snack', 'Dessert']);
const mealTypes = ref([{ name: 'Breakfast', id: '1' }, { name: 'Lunch', id: '2' }, { name: 'Dinner', id: '3' }, { name: 'Snack', id: '4' }]);
const timeList = ref(['Under 30 minutes', '30-60 minutes', 'Over 60 minutes']);
// Filter state
const showFilterDropdown = ref(false);


// Computed property for active filters
const activeFilters = computed(() => {
  return Object.entries(selectedFilters.value).flatMap(([type, values]) => 
    values.map(value => ({ type, value }))
  );
});



// Clear all filters
const clearFilters = () => {
  Object.keys(selectedFilters.value).forEach(key => {
    selectedFilters.value[key] = [];
  });
};
// Remove a single filter
const removeFilter = (filter) => {
  selectedFilters.value[filter.type] = selectedFilters.value[filter.type].filter(v => v !== filter.value);
  fetchRecipes(true, useIngredients.value);
};
const isFormReady = computed(() => {
  return cuisines.value.length > 0 && availableIngredients.value.length > 0;
});
const recipeStats = ref(null);

const generateStats = async () => {
  try {
    const response = await axios.get('/api/recipe-stats/', {
      headers: {
        'Authorization': `Bearer ${store.state.token}`
      }
    });
    recipeStats.value = response.data;
    showStatsModal.value = true;
  } catch (error) {
    console.error('Error generating stats:', error);
    // Handle error (e.g., show error message to user)
  }
};
const sortedIngredients = computed(() => {
  if (!recipeStats.value || !recipeStats.value.ingredient_stats) return []
  return [...recipeStats.value.ingredient_stats].sort((a, b) => b.count - a.count)
})
const getApprovalStatusText = (status) => {
      const statusMap = {
        'not_submitted': 'Not Submitted',
        'pending': 'Pending Approval',
        'approved': 'Approved',
        'rejected': 'Rejected'
      };
      return statusMap[status] || status;
};
const setActiveFilter = (filter) => {
  activeFilter.value = filter;
  fetchRecipes(true, useIngredients.value);
};
const showStatsModal = ref(false);
const performSearch = () => {
    isSearching.value = true;
    fetchRecipes(true, useIngredients.value);
};
watch(searchQuery, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    debouncedSearch();
  }
});
const debouncedSearch = debounce(() => {
  performSearch();
}, 750);
const closeStatsModal = () => {
  showStatsModal.value = false;
};
const selectedFilters = ref({
  diet: [],
  cuisine: [],
  mealType: [],
  time: []
});
const fetchRecipes = async (reset = false, useIngredients = false) => {
  if (!canSearch.value) return;
  if (loading.value || (!hasMoreRecipes.value && !reset)) return;
  loading.value = true;
  if (reset) {
    page.value = 1;
    recipes.value = [];
  }
  try {
    const response = await axios.get('api/recipes/', {
      params: {
        page: page.value,
        per_page: itemsPerPage,
        meal_types: selectedFilters.value.mealType.join(','),
        cuisine: selectedFilters.value.cuisine.join(','),
        diets: selectedFilters.value.diet.join(','),
        time: selectedFilters.value.time.join(','),
        search: searchQuery.value,
        ordering: sortOrder.value,
        user_recipes: activeFilter.value === 'user' ? 'true' : undefined,
        use_ingredients: useIngredients ? 'true' : undefined,
        portions: selectedPortions.value,
        store: selectedStore.value,
        include_spices: selectedSeasonings.value
      },
      headers: {
        'Authorization': `Bearer ${store.state.token}`
      }
    });
    if (response.status === 200) {
      const newRecipes = response.data.results;
      
      // Process the new recipes to include cost and missing ingredients
      const processedRecipes = newRecipes.map(recipe => ({
        ...recipe,
        cost: recipe.cost !== undefined ? recipe.cost : null,
        missingIngredients: recipe.missing_ingredients || []
      }));
      
      recipes.value = reset ? processedRecipes : [...recipes.value, ...processedRecipes];
      page.value++;
      hasMoreRecipes.value = !!response.data.next;
    } else {
      console.error('Failed to fetch recipes');
    }
  } catch (error) {
    console.error('Error fetching recipes:', error);
  } finally {
    loading.value = false;
    isSearching.value = false;
    if (props.useIngredients) isFirstIngredientSearch.value = false;
  }
};

const loadMoreRecipes = () => {
  fetchRecipes(false, useIngredients.value);
};
const editRecipe = (recipe) => {
  editingRecipe.value = recipe;
  showRecipeForm.value = true;
};

onMounted(async () => {
  window.scrollTo(0, 0);
  if (authenticated.value) {
    try {
    const response = await axios.get('/api/ingredients/all');
    availableIngredients.value = response.data.map(ingredient => ({
      ...ingredient,
      measurements: ingredient.measurements.map(m => ({ id: m.id, name: m.name }))
    }));
    // sort ingreditns by name
    availableIngredients.value.sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      console.error('Error loading ingredients:', error);
    }
  }
});
onMounted(async () => {
  if (useIngredients.value) {
    sortOrder.value = 'cost';
  }
  try {
    const response = await axios.get('api/infoforrecipe/', {
      headers: {
        'Authorization': `Bearer ${store.state.token}`
      }
    });
    cuisines.value = response.data.cuisine;
  } catch (error) {
    console.error('Error fetching cuisine types:', error);
  }
});
const submitForApproval = (recipe) => {
  recipe.approval_status = 'pending';
  fetch(`api/recipes/${recipe.id}/submit-for-approval/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.state.token}`
    },
  })
};

const approveRejectRecipe = (recipe, a) => {
  fetch(`api/recipes/${recipe.id}/approve-reject/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.state.token}`
    },
    body: JSON.stringify({ action: a })
  }).then(response => {
    if (!response.ok) {
      throw new Error('Failed to update recipe status');
    }
    return response.json();
  }).then(data => {
    fetchRecipes(true);
  }).catch(error => {
    console.error('Error updating recipe status:', error);
    alert('Failed to update recipe status. Please try again.');
  });
};



const enabledToggle = (recipe) => {
  event.stopPropagation();
  fetch(`api/recipes/${recipe.id}/toggle-enabled/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.state.token}`
    },
    body: JSON.stringify({ enabled: recipe.userEnabled })
  }).then(response => {
    if (!response.ok) {
      throw new Error('Failed to update recipe status');
    }
    return response.json();
  }).then(data => {
    // Optionally update any other data returned from the server
  }).catch(error => {
    console.error('Error updating recipe status:', error);
    // Revert the local state if the API call fails
    recipe.userEnabled = !recipe.userEnabled;
    // Notify the user
    alert('Failed to update recipe status. Please try again.');
  });
};
const changePhoto = (recipe) => {

  fetch(`api/recipes/${recipe.id}/change-photo/`, {

    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.state.token}`
    },
  }).then(response => {
    if (!response.ok) {
      throw new Error('Failed to update recipe status');
    }
    return response.json();
  }).then(data => {
    console.log('Updated recipe status:', data);
  }).catch(error => {
    console.error('Error updating recipe status:', error);
    alert('Failed to update recipe status. Please try again.');
  });
};


const debounceSearch = debounce(() => {
  fetchRecipes(true, useIngredients.value);
}, 300);

const getOptimizedImageUrl = (url) => {
  return `${url}?w=300&h=200&fit=crop`;
};

watch(sortOrder, () => {
  fetchRecipes(true, useIngredients.value);
});

onMounted(() => {
  if (!props.useIngredients) fetchRecipes(true, useIngredients.value);
});
// Watch for changes in filters and trigger a fetch
watch(selectedFilters, () => {
  fetchRecipes(true, useIngredients.value);
}, { deep: true });
</script>


<style scoped>
@keyframes border-beam {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}


/* Add any additional styles here */
.filter-dropdown-enter-active,
.filter-dropdown-leave-active {
  transition: opacity 0.3s ease;
}
.filter-dropdown-enter-from,
.filter-dropdown-leave-to {
  opacity: 0;
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>